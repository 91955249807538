import Model from './model';
import Log from '../../util/log';
import ErrorPopup from '../../features/error';
import VlabsError from '../../manager/vlabs-errors';

import { FormattedToken } from '../../types/common';

export type OtpResendHandler = (token: string) => Promise<string>;
export type OtpContinueHandler = (token: string, otp: string) => Promise<string>;

export default class Presenter {
  model: Model;

  formattedToken: FormattedToken;

  numInputs: number;

  resendHandler: OtpResendHandler;

  continueHandler: OtpContinueHandler;

  constructor(
    model: Model,
    formattedToken: FormattedToken,
    numInputs: number,
    resendHandler: OtpResendHandler,
    continueHandler: OtpContinueHandler
  ) {
    this.formattedToken = formattedToken;
    this.model = model;
    this.numInputs = numInputs;
    this.resendHandler = resendHandler;
    this.continueHandler = continueHandler;

    if (!this.formattedToken) {
      this.model.error = 'unknown';
    }
  }

  async onAttach(): Promise<void> {
    Log.info('Presenter onAttach', this.model);
  }

  onOtpChange(otp: string): void {
    this.model.otp = otp;
    this.model.error = null;
    this.model.isContinueEnabled = otp.length === this.numInputs;
  }

  async onResendClick(): Promise<void> {
    if (this.model.isResendOnCooldown) {
      Log.info('OTP > Resend is still cooling down.');
      return;
    }

    this.model.otp = '';
    this.model.error = null;
    this.model.loading = true;

    this.model.isResendOnCooldown = true;
    setTimeout(() => {
      this.model.isResendOnCooldown = false;
    }, 5000);

    try {
      const value = await this.resendHandler(this.formattedToken.value);
      Log.info('notificationType', value);
      this.model.loading = false;
    } catch (error) {
      Log.error('OTP > Continue error: ', error);
      if (VlabsError.isValid(error)) {
        ErrorPopup.show(error);
      } else {
        ErrorPopup.showDefault();
      }
    }

    // const params = {
    //   user: this.formattedToken.value,
    // };

    // VlabsUser.go(
    //   '/confirm',
    //   params,
    //   (_, notificationType) => {
    //     Log.info('notificationType', notificationType);
    //     this.model.loading = false;
    //   },
    //   (error) => {
    //     Log.error('OTP > Resend error: ', error);
    //     this.model.loading = false;
    //     ErrorPopup.show(error);
    //   }
    // );
  }

  async onContinueClick(): Promise<void> {
    this.model.loading = true;

    try {
      const value = await this.continueHandler(this.formattedToken.value, this.model.otp);
      this.model.loading = false;

      // FIXME: Verify how this should this be handled?
      // 'Prompt' is being returned when either the token is unrecognized or the otp is invalid.

      if (value === 'prompt') {
        Log.warn('OTP > VlabsUser SDK asked for prompt');
        this.model.error = 'invalid_otp';
      }
    } catch (error) {
      if (VlabsError.isValid(error)) {
        ErrorPopup.show(error);
      } else {
        ErrorPopup.showDefault();
      }
      Log.error('OTP > Continue error: ', error);
    }

    // const params = {
    //   user: this.formattedToken.value,
    //   password: this.model.otp,
    // };

    // VlabsUser.go(
    //   '/confirm',
    //   params,
    //   (_, notificationType) => {
    //     Log.info('notificationType', notificationType);
    //     this.model.loading = false;
    //   },
    //   (error) => {
    //     Log.error('OTP > Continue error: ', error);
    //     this.model.loading = false;
    //     ErrorPopup.show(error);
    //   }
    // ).then((value) => {
    //   // FIXME: Verify how this should this be handled?
    //   // 'Prompt' is being returned when either the token is unrecognized or the otp is invalid.

    //   this.model.loading = false;

    //   if (value === 'prompt') {
    //     Log.warn('OTP > VlabsUser SDK asked for prompt');
    //     this.model.error = 'invalid_otp';
    //   }
    // });
  }
}
