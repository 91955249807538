// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QHR4T3q982t8Yjr68iXL{background:#fff;color:#000;border-radius:0px;border:thin solid #e1e1e1}.QHR4T3q982t8Yjr68iXL:hover{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/button-google/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,UAAA,CACA,iBAAA,CACA,yBAAA,CAGF,4BACE,cAAA","sourcesContent":[".button {\n  background: #ffffff;\n  color: #000000;\n  border-radius: 0px;\n  border: thin solid #e1e1e1;\n}\n\n.button:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `QHR4T3q982t8Yjr68iXL`
};
export default ___CSS_LOADER_EXPORT___;
