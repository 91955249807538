// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.URMEJBNkg4wz4ECsMOsc{display:inline-block;padding-top:4px;width:34px;height:34px;font-size:13px;color:#fff;border-radius:50%;cursor:pointer;border:none}.m1Yl_2HJbTk0fMD_bpbv{cursor:default}`, "",{"version":3,"sources":["webpack://./src/components/button-back/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA,CACA,WAAA,CAGF,sBACE,cAAA","sourcesContent":[".button {\n  display: inline-block;\n  padding-top: 4px;\n  width: 34px;\n  height: 34px;\n  font-size: 13px;\n  color: #fff;\n  border-radius: 50%;\n  cursor: pointer;\n  border: none;\n}\n\n.disabled {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `URMEJBNkg4wz4ECsMOsc`,
	"disabled": `m1Yl_2HJbTk0fMD_bpbv`
};
export default ___CSS_LOADER_EXPORT___;
