// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ISeMjsNUHPVkHoAZ5F2f{display:flex;flex-direction:column;gap:8px}`, "",{"version":3,"sources":["webpack://./src/features/claim/consent-popup/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".additionalContentsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalContentsContainer": `ISeMjsNUHPVkHoAZ5F2f`
};
export default ___CSS_LOADER_EXPORT___;
