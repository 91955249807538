import clsx from 'clsx';
import i18next, { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ViewBase from '../../../views/view-base';
import Presenter from './presenter';
import styles from './view.module.scss';
import Image from '../../../components/image';
import { Config } from '../../../types/config';

interface ViewProps {
  t: TFunction;
  // eslint-disable-next-line react/no-unused-prop-types
  presenter: Presenter;
  config: Config;
}

function View(props: ViewProps): JSX.Element {
  const { t, config } = props;

  const backgroundImage = i18next.exists('claim.expired.background_image.src')
    ? t('claim.expired.background_image.src')
    : '';

  const contextImageSrc: string | undefined | null =
    config.claim?.style?.expired?.context_image?.src;

  return (
    <ViewBase
      headerText={t('claim.expired.text')}
      footerText={t('claim.expired.footer')}
      prefix="claim-expired"
      backgroundImageSrc={backgroundImage}
    >
      <div className={clsx('login-expired-image-container', styles.contextImageContainer)}>
        <Image
          alt=""
          className={clsx('login-expired-image', styles.contextImage)}
          src={contextImageSrc}
        />
      </div>
    </ViewBase>
  );
}

export default withTranslation()(View);
