class Alert {
  constructor() {
    this.id = 0;
    this.alerts = new Map();
    this.open = {};
  }

  setProvider(provider) {
    this.provider = provider;
  }

  getId() {
    const { id } = this;
    this.id += 1;
    return id;
  }

  updateAlerts() {
    const alerts = [];
    this.alerts.forEach((value) => {
      alerts.push(value.alert(value.open));
    });
    this.provider(alerts);
  }

  showAlert(id, alert) {
    this.alerts.set(id, { alert, open: true });
    this.updateAlerts();
  }

  removeAlert(id) {
    const alert = this.alerts.get(id) || {};
    alert.open = false;
    this.alerts.set(id, alert);
    setTimeout(() => {
      this.alerts.delete(id);
    }, 1000);
    this.updateAlerts();
  }

  show(factory) {
    const id = this.getId();
    this.showAlert(
      id,
      factory(id, () => {
        this.removeAlert(id);
      })
    );
  }
}
export default new Alert();
