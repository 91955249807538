import clsx from 'clsx';
import { TFunction, i18n as i18nType } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Image from '../../../components/image';
import Label from '../../../components/label';
import { FormattedToken } from '../../../types/common';
import { Config } from '../../../types/config';
import { NotificationType } from '../../../types/vlabs-user';
import ViewBase from '../../../views/view-base';
import Presenter from './presenter';
import styles from './view.module.scss';

interface ViewProps {
  t: TFunction;
  i18n: i18nType;
  // eslint-disable-next-line react/no-unused-prop-types
  presenter: Presenter;
  notificationType: NotificationType | null;
  formattedToken: FormattedToken;
  config: Config;
}

function View(props: ViewProps): JSX.Element {
  const { t, i18n, notificationType, formattedToken, config } = props;

  const backgroundImage = i18n.exists('claim.link.background_image.src')
    ? t('claim.link.background_image.src')
    : '';

  const linkImageSrc: string | undefined | null = config.claim?.style?.link?.link_image?.src;

  let headerLabel = '';
  let label = '';
  if (notificationType === 'login') {
    if (formattedToken.type === 'phone') {
      label = 'claim.link.login_text_phone_only';
      headerLabel = 'claim.link.login_header_text_phone_only';
    } else {
      label = 'claim.link.login_text_email_only';
      headerLabel = 'claim.link.login_header_text_email_only';
    }
  } else if (formattedToken.type === 'phone') {
    label = 'claim.link.verify_text_phone_only';
    headerLabel = 'claim.link.verify_header_text_phone_only';
  } else {
    label = 'claim.link.verify_text_email_only';
    headerLabel = 'claim.link.verify_header_text_email_only';
  }
  return (
    <div className={clsx('claim-link-container', styles.container)}>
      <ViewBase
        prefix="claim-link"
        headerText={t(headerLabel)}
        backgroundImageSrc={backgroundImage}
      >
        <div className={clsx('login-link-image-container', styles.contextImageContainer)}>
          <Image
            alt=""
            className={clsx('login-link-image', styles.contextImage)}
            src={linkImageSrc}
          />
        </div>
        <Label className={clsx('claim-link-text', styles.linkText)} text={t(label)} />
      </ViewBase>
    </div>
  );
}

export default withTranslation()(View);
