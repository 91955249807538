export interface VlabsSDKError {
  code: number | string;
  message?: string;
  note?: string;
}

class Errors {
  // Check if the object conforms to the interface's shape.
  isValid(obj: any): obj is VlabsSDKError {
    return typeof obj === 'object' && 'code' in obj && typeof obj.code === 'number';
  }
}

export default new Errors();
