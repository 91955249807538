import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction, i18n as i18nType } from 'i18next';
import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';
import Countries from '../../util/countries';
import Image from '../../components/image';
import styles from './view.module.scss';
import Log from '../../util/log';
import { Config } from '../../types/config';
import ViewAnchor from '../../views/view-anchor';
import ViewBase from '../../views/view-base';

export interface ViewProps {
  t: TFunction;
  i18n: i18nType;
  config: Config;
  availableComponent: () => JSX.Element;
  onComplete: () => void;
}

function GeoBlocker(props: ViewProps): JSX.Element {
  const { t, i18n, config, availableComponent, onComplete } = props;

  const [geoAvailable, setGeoAvailable] = useState<boolean | null>(null);

  const matches = useMediaQuery('(max-width: 856px)');

  // Ensure allowed_regions is present and contains something
  const checkGeoBlocker =
    config.geo_blocking?.allowed_regions && config.geo_blocking.allowed_regions.length !== 0;

  useEffect(() => {
    if (checkGeoBlocker) {
      // Get API key
      const apiKey = config.ipregistry_api_key;
      if (!apiKey) {
        Log.warn('GeoBlocker ipregistry_api_key not set.');
        setGeoAvailable(true);
        return;
      }

      // Get whitelist from config
      const countryWhitelist = config.geo_blocking?.allowed_regions?.map((code) =>
        code.toLowerCase()
      );

      if (!countryWhitelist) {
        setGeoAvailable(true);
        return;
      }

      // Find user country code
      Countries.detectIPRegistry(apiKey)
        .then((countryInfo) => {
          // Check if any info was returned
          if (!countryInfo) {
            setGeoAvailable(true);
            return;
          }

          const code = countryInfo.code.toLowerCase();

          // Check if whitelisted
          if (countryWhitelist.includes(code)) {
            setGeoAvailable(true);
          } else {
            Log.error(`GeoBlocker > Country code ${code} blocked.`);
            // only dismiss loader in failure case
            onComplete();
            setGeoAvailable(false);
          }
        })
        .catch((error) => {
          Log.error(`GeoBlocker > Encountered on error ${error}.`);
          onComplete();
          setGeoAvailable(false);
        });
    } else {
      setGeoAvailable(true);
    }
  }, []);

  // Check if whitelisted
  if (geoAvailable) {
    return availableComponent();
  }

  const fallbackPage = (
    <ViewAnchor t={t} prefix="geo_blocking">
      <div className={clsx('geo-blocking-container', styles.container)}>
        {matches && (
          <div className={clsx('geo-blocking-background-image-container', styles.imageContainer)}>
            <Image
              alt=""
              src={
                i18n.exists('geo_blocking.background_image.src')
                  ? t('geo_blocking.background_image.src')
                  : ''
              }
              className={clsx('geo-blocking-background-image', styles.image)}
            />
          </div>
        )}
        <div
          className={clsx(
            matches ? 'geo-blocking-inner-container-mobile' : 'geo-blocking-inner-container',
            styles.innerContainer
          )}
        >
          <ViewBase
            prefix="geo-blocking"
            headerText={t('geo_blocking.text')}
            headerStyles={clsx(styles.message)}
          />
        </div>
      </div>
    </ViewAnchor>
  );

  // Show geo restricted
  return fallbackPage;
}

export default withTranslation()(GeoBlocker);
