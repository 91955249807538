/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Slide, { SlideProps } from '@mui/material/Slide';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import Label from '../../components/label';
import styles from './view.module.scss';
import { CountryInfo } from '../../util/countries';
import Presenter from './presenter';

export interface ViewProps {
  t: TFunction;
  presenter: Presenter;
  open: boolean;
  selectedCountry: CountryInfo | null;
  countries: CountryInfo[];
}

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function View(props: ViewProps): JSX.Element {
  const { presenter, open, selectedCountry, countries, t } = props;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={(): void => presenter?.onCloseClick()}
      TransitionComponent={Transition}
    >
      <div className={clsx(styles.content, 'country-picker-content')}>
        <div className={clsx(styles.toolbar, 'country-picker-toolbar')}>
          <Label className={clsx(styles.title, 'country-picker-title')} text={t('country.title')} />
        </div>
        <div className={clsx(styles.divider, 'country-picker-toolbar-divider')} />
        <div className={clsx(styles.body, 'country-picker-body')}>
          {selectedCountry && (
            <div
              className={clsx(styles.listItem, 'country-picker-list-item')}
              key={selectedCountry.name}
              onClick={(): void => presenter.onCountrySelect(selectedCountry)}
            >
              <Label
                className={clsx(styles.text, 'country-picker-listitem-text')}
                text={`${selectedCountry.name} (${selectedCountry.phonePrefix})`}
              />
              <div className={clsx(styles.divider, 'country-picker-listitem-divider')} />
            </div>
          )}
          {countries?.map((country) => {
            return (
              <div
                className={clsx(styles.listItem, 'country-picker-list-item')}
                key={country.name}
                onClick={(): void => presenter.onCountrySelect(country)}
              >
                <Label
                  className={clsx(styles.text, 'country-picker-listitem-text')}
                  text={`${country.name} (${country.phonePrefix})`}
                />
                <div className={clsx(styles.divider, 'country-picker-listitem-divider')} />
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
}
export default withTranslation()(View);
