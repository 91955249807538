import BaseModel from '../../../util/model';

export interface StateModel {
  selectedDate: any;
  errorText: string;
}

export default class Model extends BaseModel implements StateModel {
  constructor(component: React.Component) {
    super(component, { errorText: '', selectedDate: null });
  }

  get selectedDate(): any {
    const { selectedDate } = this.value;
    return selectedDate;
  }

  set selectedDate(value: any) {
    this.value = { selectedDate: value };
  }

  get errorText(): string {
    const { errorText } = this.value;
    return errorText;
  }

  set errorText(value: string) {
    this.value = { errorText: value };
  }
}
