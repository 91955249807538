import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const styles = [];

  const { primary, backdrop: backdropColor, background: backgroundColor } = theme.palette ?? {};

  if (backdropColor && backdropColor.length > 0) {
    styles.push(`.anchor-page-backdrop {background-color: ${backdropColor};}`);
  }

  if (primary && primary.length > 0) {
    styles.push(`.anchor-page-left-container {background-color: ${primary};}`);
  }

  if (backgroundColor && backgroundColor.length > 0) {
    styles.push(`.anchor-page-content-container {background-color: ${backgroundColor};}`);
  }

  return styles;
}
