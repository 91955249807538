// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wa7vixtXiU2jiRdDOsc_{box-sizing:border-box;width:100%;height:100%;display:flex;flex-direction:column;align-items:center}.uEBPH868YesuXKJKjwUN{box-sizing:border-box;display:flex;flex-direction:column;align-items:center;width:100%;height:100%;position:absolute}.uEBPH868YesuXKJKjwUN>*{z-index:1}.AYQRihLOm5IwYZalwAP6{width:100%;object-fit:contain}.FABDYee9kJGvvQ24SVpb{overflow:visible}._36UY2xfNf7n1n3w9gyQ{text-align:center}.RJ9noaX0zUz8mhQAQmf0{flex:1}.hf7QiWIBT9b1TqIrLOcC{text-align:center}`, "",{"version":3,"sources":["webpack://./src/features/geo-blocker/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAGF,sBACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,wBACE,SAAA,CAGF,sBACE,UAAA,CAEA,kBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,MAAA,CAGF,sBACE,iBAAA","sourcesContent":[".container {\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.innerContainer {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.innerContainer > * {\n  z-index: 1;\n}\n\n.image {\n  width: 100%;\n  // height: auto;\n  object-fit: contain;\n}\n\n.imageContainer {\n  overflow: visible;\n}\n\n.message {\n  text-align: center;\n}\n\n.footerSpace {\n  flex: 1;\n}\n\n.footer {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `wa7vixtXiU2jiRdDOsc_`,
	"innerContainer": `uEBPH868YesuXKJKjwUN`,
	"image": `AYQRihLOm5IwYZalwAP6`,
	"imageContainer": `FABDYee9kJGvvQ24SVpb`,
	"message": `_36UY2xfNf7n1n3w9gyQ`,
	"footerSpace": `RJ9noaX0zUz8mhQAQmf0`,
	"footer": `hf7QiWIBT9b1TqIrLOcC`
};
export default ___CSS_LOADER_EXPORT___;
