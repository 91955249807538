import React, { useState } from 'react';

export default function Image(props) {
  const { src } = props;
  const [source, setSource] = useState(null);
  return (
    <img
      alt=""
      ref={(element) => {
        if (element != null) {
          const styles = getComputedStyle(element);
          let content = styles?.content;
          if (content && content.startsWith('url(') && content.endsWith(')')) {
            content = content.replace('url(', '');
            content = content.substring(0, content.length - 1);
            content = content.replaceAll('"', '').replaceAll("'", '');
            if (content !== source) {
              setSource(content);
            }
          }
        }
      }}
      {...props}
      src={src || source}
    />
  );
}
