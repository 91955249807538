import Model from './model';

export default class Presenter {
  model: Model;

  delay: number | undefined;

  onContinue: VoidFunction;

  onClose: VoidFunction;

  constructor(
    model: Model,
    delay: number | undefined,
    onContinue: VoidFunction,
    onClose: VoidFunction
  ) {
    this.model = model;
    this.delay = delay;
    this.onContinue = onContinue;
    this.onClose = onClose;
  }

  onAttach(): void {
    if (this.delay) {
      setTimeout(() => {
        this.onClose?.();
      }, this.delay);
    }
  }

  onContinueClick(): void {
    this.onContinue();
  }

  onCloseClick(): void {
    this.onClose?.();
  }
}
