import React, { Component } from 'react';
import isMobile from 'is-mobile';
import Presenter from './presenter';
import Model, { StateModel } from './model';
import View from './view';
import { Config } from '../../types/config';

type CallbackFunction = () => void;

interface ClaimMultiProps {
  hash: string;
  config: any;
  removeLoader: CallbackFunction;
}

export default class ClaimMulti extends Component<ClaimMultiProps> {
  presenter: Presenter;

  castConfig: Config;

  constructor(props: ClaimMultiProps | Readonly<ClaimMultiProps>) {
    super(props);
    const { config, removeLoader } = props;

    // TODO: Replace with something safer.
    this.castConfig = config as Config;

    const showMobileOnly =
      (config.view_mode === 'mobile-only' || config.view_mode === 'mobile-first') &&
      !isMobile({ featureDetect: true, tablet: true });

    // Initialize the custom input from config.
    const { custom_input: inputCustom, consent } = this.castConfig.claim;
    const isConsentPopupContinueEnabled = !consent?.default?.enabled;
    const model = new Model(this, inputCustom, isConsentPopupContinueEnabled, showMobileOnly);
    this.presenter = new Presenter(model, this.castConfig, removeLoader);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(prevProp: Readonly<ClaimMultiProps>): void {
    if (this.props !== prevProp) {
      const { hash, config } = this.props;
      this.presenter.update(hash, config);
    }
  }

  render(): JSX.Element {
    return (
      <View {...(this.state as StateModel)} config={this.castConfig} presenter={this.presenter} />
    );
  }
}
