import React from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FallbackProps } from 'react-error-boundary';
import { t } from 'i18next';
import styles from './view.module.scss';
import Log from '../../util/log';
import Label from '../../components/label';

function View({ error }: FallbackProps): JSX.Element {
  Log.error('[ErrorBoundary]', error);
  return (
    <div role="alert" className={clsx(styles.container)}>
      <div className={clsx(styles.innerContainer)}>
        <Label className={clsx(styles.text)} text={t('errors_v2.default.title')} variant="h6" />
        <Label className={clsx(styles.text)} text={t('errors_v2.default.body')} variant="body2" />
      </div>
    </div>
  );
}

export default withTranslation()(View);
