/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';
import SMSIcon from './logo-email-30.png';
import PhoneIcon from './logo-sms-30.png';
import MailOrSMSIcon from './logo-email-or-sms-30.png';
import Label from '../label';
import { ButtonSize, ButtonVariant } from '../../types/theme';

export type TokenAuthOption = 'email' | 'phone' | 'emailOrPhone';

export interface IndexProps {
  className: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  text: string;
  option: TokenAuthOption;
  variant?: ButtonVariant;
}

export default function Button(props: IndexProps): JSX.Element {
  const { className, disabled, onClick, text, option, variant } = props;

  const size: ButtonSize = 'large';

  // Render button
  return (
    <div
      className={clsx(
        className,
        'token-button',
        `base-button-${size}`,
        !disabled && `base-button-${variant}-active`,
        disabled && `base-button-${variant}-inactive`,
        disabled && 'disabled',
        !disabled && styles.buttonActive,
        styles.button,
        disabled && styles.disabled
      )}
      onClick={(event): void | false => !disabled && onClick?.(event)}
    >
      <div>
        {option === 'email' && <img className="token-button-icon" alt="" src={SMSIcon} />}
        {option === 'phone' && <img className="token-button-icon" alt="" src={PhoneIcon} />}

        {option === 'emailOrPhone' && (
          <img className="token-button-icon" alt="" src={MailOrSMSIcon} />
        )}
      </div>

      <Label className={clsx('token-button-label', 'button-label')} text={text} />
    </div>
  );
}

Button.defaultProps = {
  disabled: false,
  variant: 'auth',
};
