import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const styles: string[] = [];

  const { top_margin: topOffset } = theme;

  if (topOffset) {
    let rules = '';
    if (topOffset && topOffset?.length > 0) {
      rules += `padding-top:${topOffset};`;

      styles.push(`.unsubscribe-landing-inner-container-mobile {${rules}}`);
      styles.push(`.unsubscribe-success-inner-container-mobile {${rules}}`);
      styles.push(`.unsubscribe-resubscribe-inner-container-mobile {${rules}}`);
    }
  }

  return styles;
}
