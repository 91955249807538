// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kmHpVZ5TsiI2fhtsrsn2{height:100%;width:100%;height:100%;display:flex;flex-direction:column;align-items:center;position:relative}.eRhcKhK3fNCcl_VtYdql{box-sizing:border-box;display:flex;flex-direction:column;align-items:center;width:100%;height:100%}.sWcdJ8eJRyVSThqOihew:active{filter:brightness(70%)}.ClMOukM3n536Gg304HCI{text-align:center;margin-bottom:14px}.FF33_JKiNEl9VxnTfcwl{text-align:center;margin-bottom:12px}.K2aq7oHc3VVjyO26BnFf{flex:1 1 auto}.zaCO89PeJAgzvFyZsikf{flex:5 1 auto}.eWXsPeT5OEcVDiDQFi2t{width:100%;padding-left:0px;padding-right:0px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/views/view-otp/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAGF,6BACE,sBAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,aAAA,CAGF,sBACE,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n\n.innerContainer {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.resend:active {\n  filter: brightness(70%);\n}\n\n.headerText {\n  text-align: center;\n  margin-bottom: 14px;\n}\n\n.text {\n  text-align: center;\n  margin-bottom: 12px;\n}\n\n.spacer1 {\n  flex: 1 1 auto;\n}\n\n.spacer5 {\n  flex: 5 1 auto;\n}\n\n.button {\n  width: 100%;\n  padding-left: 0px;\n  padding-right: 0px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kmHpVZ5TsiI2fhtsrsn2`,
	"innerContainer": `eRhcKhK3fNCcl_VtYdql`,
	"resend": `sWcdJ8eJRyVSThqOihew`,
	"headerText": `ClMOukM3n536Gg304HCI`,
	"text": `FF33_JKiNEl9VxnTfcwl`,
	"spacer1": `K2aq7oHc3VVjyO26BnFf`,
	"spacer5": `zaCO89PeJAgzvFyZsikf`,
	"button": `eWXsPeT5OEcVDiDQFi2t`
};
export default ___CSS_LOADER_EXPORT___;
