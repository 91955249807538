import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import Model from './model';
import { validateEmail, validatePhone } from '../../../util/validation';
import { Config, ConsentType, CustomInputItem, Policies, Token } from '../../../types/config';
import Countries, { CountryInfo } from '../../../util/countries';
import CountryPicker from '../../country-select';
import { NotificationType, VLabsParams } from '../../../types/vlabs-user';
import Log from '../../../util/log';
import ErrorPopup from '../../error';
import { AuthProvider, FormattedToken } from '../../../types/common';

export default class Presenter {
  advertiserName: string;

  age: any;

  config: Config;

  dateOfBirth: string | null;

  language: string;

  model: Model;

  selectedCountry: CountryInfo | null = null;

  token: Token;

  acceptedPrimaryConsent: boolean;

  acceptedMarketingConsent: boolean;

  inputToken: string;

  formattedToken: FormattedToken | null = null;

  inputName: string;

  inputCustom: CustomInputItem[] | null;

  inputAdditional: string | null;

  isAuthComplete: boolean;

  consentPolicies: Policies;

  onConsentChange: (type: ConsentType, consent: boolean) => void;

  onRegister: (notificationType: string, formattedToken?: FormattedToken) => void;

  onBack: VoidFunction;

  constructor(
    model: Model,
    config: Config,
    inputToken: string,
    inputName: string,
    inputCustom: CustomInputItem[] | null,
    inputAdditional: string | null,
    acceptedPrimaryConsent: boolean,
    acceptedMarketingConsent: boolean,
    language: string,
    dateOfBirth: string | null,
    isAuthComplete: boolean,
    onConsentChange: (type: ConsentType, consent: boolean) => void,
    onBack: VoidFunction,
    onRegister: (notificationType: string, formattedToken?: FormattedToken) => void
  ) {
    this.model = model;
    this.advertiserName = config.advertiser_name ?? '';
    this.config = config;
    this.age = config.age;
    this.token = config.token;
    this.inputToken = inputToken;
    this.inputName = inputName;
    this.inputCustom = inputCustom;
    this.inputAdditional = inputAdditional;
    this.acceptedPrimaryConsent = acceptedPrimaryConsent;
    this.acceptedMarketingConsent = acceptedMarketingConsent;
    this.language = language;
    this.dateOfBirth = dateOfBirth;
    this.isAuthComplete = isAuthComplete;
    this.consentPolicies = config.claim.consent.policies;
    this.onConsentChange = onConsentChange;
    this.onBack = onBack;
    this.onRegister = onRegister;
  }

  async onAttach(): Promise<void> {
    const { allow_phone: allowPhone, allow_email: allowEmail, regions } = this.token;
    const {
      consent,
      capture_additional: captureAdditional,
      capture_name: captureName,
      name_type: nameType,
    } = this.config.claim;

    this.model.allowPhone = allowPhone;
    this.model.allowEmail = allowEmail;
    this.model.captureName = captureName;
    this.model.nameType = nameType ?? null;
    this.model.captureAdditional = captureAdditional;
    this.model.advertiserName = this.advertiserName;

    const { marketing: marketingConsent } = consent ?? {};

    this.model.showMarketingConsent = marketingConsent?.enabled ?? false;
    this.model.marketingConsentTerms = marketingConsent?.terms ?? '';

    let defaultCountry: CountryInfo = {
      code: 'us',
      phonePrefix: '+1',
      name: 'United States',
      label: '+1',
    };
    if (regions && regions?.length > 0) {
      const result = (await Countries.get().catch(() => [])).find(
        (country) => country.code.toLowerCase() === regions[0].toLowerCase()
      );
      if (result) {
        defaultCountry = result;
      }
    }

    let detected = (await Countries.detect().catch(() => {})) || defaultCountry;
    if (!regions?.find((country) => detected.code.toLowerCase() === country.toLowerCase())) {
      detected = defaultCountry;
    }
    if (regions?.length === 1) {
      this.model.disableCountryPicker = true;
    }
    this.selectedCountry = detected;
    this.model.countryCode = this.selectedCountry?.phonePrefix ?? null;
  }

  onTokenChange(value: string): void {
    const { allow_phone: allowPhone } = this.token;

    if (allowPhone && validatePhone(value) && value.indexOf('+') !== 0) {
      this.model.showCountryCode = true;
    } else {
      this.model.showCountryCode = false;
    }
  }

  onCountryCodeClick(): void {
    const { regions } = this.token;
    CountryPicker.show(this.selectedCountry, regions ?? [], null, (selected: CountryInfo) => {
      if (selected) {
        this.selectedCountry = selected;
        this.model.countryCode = this.selectedCountry.phonePrefix;
      }
    });
  }

  validateForm(provider: AuthProvider): boolean {
    this.inputToken = this.inputToken?.trim();
    const { allow_phone: allowPhone, allow_email: allowEmail } = this.token;

    const { capture_name: captureName, capture_additional: captureAdditional } = this.config.claim;

    let hasErrors = false;

    if (provider === 'token') {
      if (allowPhone && allowEmail) {
        if (!validateEmail(this.inputToken) && !validatePhone(this.inputToken)) {
          this.model.errorToken = 'claim.landing.error_invalid_email_phone_number';
          hasErrors = true;
        } else this.model.errorToken = null;
      } else if (allowEmail) {
        if (!validateEmail(this.inputToken)) {
          this.model.errorToken = 'claim.landing.error_invalid_email';
          hasErrors = true;
        } else this.model.errorToken = null;
      } else if (allowPhone) {
        if (!validatePhone(this.inputToken)) {
          this.model.errorToken = 'claim.landing.error_invalid_phone_number';
          hasErrors = true;
        } else this.model.errorToken = null;
      }

      if (allowEmail && validateEmail(this.inputToken)) {
        this.formattedToken = { value: this.inputToken, type: 'email' };
      } else if (allowPhone && validatePhone(this.inputToken)) {
        try {
          const phoneUtil = PhoneNumberUtil.getInstance();
          if (!this.selectedCountry?.code) {
            this.model.errorToken = 'claim.landing.error_invalid_region';
          } else {
            const phoneNumber = phoneUtil.parse(this.inputToken, this.selectedCountry.code);
            const token = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164).trim();
            this.formattedToken = { value: token, type: 'phone' };
            if (!phoneUtil.isValidNumber(phoneNumber)) {
              this.model.errorToken = 'claim.landing.error_invalid_phone_number';
              hasErrors = true;
            } else if (!token.startsWith(this.selectedCountry.phonePrefix)) {
              this.model.errorToken = 'claim.landing.error_invalid_region';
              hasErrors = true;
            } else if (token.length > 0) {
              this.inputToken = token;
              this.model.errorToken = null;
            }
          }
        } catch (error) {
          Log.error(error);
          this.model.errorToken = 'claim.landing.error_invalid_phone_number';
          hasErrors = true;
        }
      }
    } else {
      this.model.errorToken = null;
      this.model.errorName = null;
    }

    if (captureAdditional && captureAdditional.length > 0) {
      if (!this.inputAdditional) {
        hasErrors = true;
        this.model.errorAdditional = 'claim.landing.additional.error_select';
      } else this.model.errorAdditional = null;
    }

    this.inputCustom?.forEach((input) => {
      if (input.required && !input.value) {
        hasErrors = true;
        // eslint-disable-next-line no-param-reassign
        input.error = input.required && 'required';
      } else {
        // eslint-disable-next-line no-param-reassign
        input.error = '';
      }
    });

    if (provider === 'token' && captureName) {
      if (this.inputName?.length === 0) {
        this.model.errorName = 'claim.landing.error_invalid_name';
        hasErrors = true;
      } else this.model.errorName = null;
    }

    if (!this.isAuthComplete) {
      if (!this.acceptedPrimaryConsent) {
        this.model.errorPrimaryConsent = 'claim.landing.error_consent';
        hasErrors = true;
      } else {
        this.model.errorPrimaryConsent = null;
      }
    }

    return hasErrors;
  }

  onNextClick(provider: AuthProvider): void {
    this.inputToken = this.inputToken?.trim();

    const { delay: errorDelay, redirect_on_go: redirectOnGoError } = this.config.error;

    // Validate form
    const hasErrors = this.validateForm(provider);

    Log.info(
      `
      Form errored            : ${hasErrors}
        errorPrimaryConsent   : ${this.model.errorPrimaryConsent}
        errorName             : ${this.model.errorName}
        errorEmail            : ${this.model.errorEmail}
        errorPhone            : ${this.model.errorPhone}
        errorToken            : ${this.model.errorToken}
        errorAdditional       : ${this.model.errorAdditional}
    `
    );

    // Build vlabs params
    if (!hasErrors) {
      this.model.errorPhone = null;
      this.model.errorEmail = null;
      this.model.errorName = null;
      this.model.loading = true;

      const params: VLabsParams = { user: this.formattedToken?.value, consent: 1 };

      if (this.inputName?.length > 0) {
        params.name = this.inputName;
      }

      if (this.inputAdditional) {
        params.select = this.inputAdditional;
      }

      params.language = this.language;

      // Get policies for the language
      const languageSpecificPolicies = this.consentPolicies[this.language];

      // Consent Framework
      if (this.acceptedPrimaryConsent) {
        // Build accepted policies
        const accepted = languageSpecificPolicies;

        // Remove marketing if enabled but not accepted
        if (
          this.config.claim.consent.marketing?.enabled === true &&
          !this.acceptedMarketingConsent
        ) {
          delete accepted.marketing;
        }
        params.policies_accepted = accepted;
      }

      // Consent Old
      if (this.config.claim.consent.marketing?.enabled === true) {
        params.optin = this.acceptedMarketingConsent ? 'yes' : 'no';
      }

      if (this.dateOfBirth) {
        params.birthday = this.dateOfBirth;
      }

      this.inputCustom?.forEach((input, index) => {
        params[`input_${index + 1}`] = input.value;
      });

      if (provider === 'token') {
        // Register the user (token flow captures input before registration).
        Log.warn(`[Debug] VlabsUser.go('/signup', params: ${JSON.stringify(params)}`);
        VlabsUser.go(
          '/signup',
          params,
          (data, notificationType: NotificationType) => {
            this.onRegister?.(notificationType, this.formattedToken!);
          },
          (error) => {
            Log.error('[Debug] .go(/signup) error:', error);
            if (error.code === 94) {
              // if error 'token_expired', push user back to start
              ErrorPopup.show(error, () => window.location.reload());
            } else {
              ErrorPopup.show(error, () => {}, redirectOnGoError && errorDelay);
            }
          }
        );
      } else {
        // Update the user (OAuth & WalletConnect capture inputs after registration).
        Log.warn(`[Debug] VlabsUser.go('/update', params: ${JSON.stringify(params)}`);
        VlabsUser.go(
          '/update',
          params,
          (_, notificationType: NotificationType) => {
            this.onRegister?.(notificationType);
          },
          (error) => {
            ErrorPopup.show(error, () => {}, redirectOnGoError && errorDelay);
            Log.error(error);
          }
        );
      }
    }
  }

  onBackClick(): void {
    this.onBack();
  }
}
