import Model from './model';
import { Config, ConsentType, Token } from '../../../types/config';
import { AuthProvider } from '../../../types/common';

export default class Presenter {
  model: Model;

  facebookAppId: string;

  googleAppId: string;

  outsideAppId: string;

  walletConnectId: string | undefined;

  oauthOnly: boolean;

  config: Config;

  token: Token;

  selectedAuthProvider: AuthProvider | null = null;

  onNext: (authProvider: AuthProvider) => void;

  onConsentChange: (type: ConsentType, consent: boolean) => void;

  constructor(
    model: Model,
    config: Config,
    onNext: (authProvider: AuthProvider) => void,
    onConsentChange: (type: ConsentType, consent: boolean) => void
  ) {
    this.facebookAppId = config.facebook_app_id;
    this.googleAppId = config.google_app_id;
    this.outsideAppId = config.outside_app_id;
    this.walletConnectId = config.wallet_connect?.project_id;
    this.oauthOnly = config.oauth_only;
    this.config = config;
    this.token = config.token;
    this.onNext = onNext;
    this.onConsentChange = onConsentChange;
    this.model = model;
  }

  async onAttach(): Promise<void> {
    const { allow_phone: allowPhone, allow_email: allowEmail } = this.token;

    this.model.allowPhone = allowPhone;
    this.model.allowEmail = allowEmail;
    this.model.showFacebook = !!this.facebookAppId;
    this.model.showGoogle = !!this.googleAppId;
    this.model.showOutsideOauth = !!this.outsideAppId;
    this.model.showWalletConnect = !!this.walletConnectId;
    this.model.oauthOnly = !!this.oauthOnly;

    const { marketing: marketingConsent } = this.config.claim.consent ?? {};

    this.model.showMarketingConsent = marketingConsent?.enabled ?? false;
    this.model.marketingConsentTerms = marketingConsent?.terms ?? '';
  }

  onAuthProviderClick(provider: AuthProvider): void {
    this.selectedAuthProvider = provider;
    if (provider !== 'token') {
      this.openConsentPopup(provider);
    } else {
      this.onNext(provider);
    }
  }

  openConsentPopup(authProvider: AuthProvider): void {
    this.selectedAuthProvider = authProvider;
    this.model.isConsentPopupOpen = true;
  }

  closeConsentPopup(): void {
    this.model.isConsentPopupOpen = false;
  }

  onConsentPopupContinue(): void {
    this.onNext(this.selectedAuthProvider!);
  }
}
