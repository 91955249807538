import React from 'react';
import { TFunction, i18n as i18nType } from 'i18next';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import Presenter from './presenter';
import Popup from '../../components/popup';
import Label from '../../components/label';
import styles from './view.module.scss';
import { VlabsSDKError } from '../../manager/vlabs-errors';

interface ViewProps {
  t: TFunction;
  i18n: i18nType;
  open: boolean;
  presenter: Presenter;
  error?: VlabsSDKError;
  delay?: number;
}

function View(props: ViewProps): JSX.Element {
  const { presenter, open, t, i18n, error, delay } = props;
  let titleKey = 'errors_v2.default.title';
  let bodyKey = 'errors_v2.default.body';

  if (error?.code) {
    const titleKeyPath = `errors_v2.${error.code}.title`;
    if (i18n.exists(titleKeyPath) && t(titleKeyPath).length > 0) {
      titleKey = titleKeyPath;
    }

    const bodyKeyPath = `errors_v2.${error.code}.body`;
    if (i18n.exists(bodyKeyPath) && t(bodyKeyPath).length > 0) {
      bodyKey = bodyKeyPath;
    }
  }
  const codeText = t('errors_v2.code_message', { code: error?.code ?? -1 });

  return (
    <Popup
      title={titleKey}
      body={(): string => t(bodyKey)}
      closeButtonTitle={t('errors_v2.popup_confirm')}
      isContinueEnabled={false}
      open={open}
      onClose={(): void => presenter?.onCloseClick()}
      delay={delay}
      onContinue={(): void => {}}
      continueButtonTitle=""
    >
      <div className={clsx(styles.codeContainer)}>
        <Label text={codeText} variant="body2" />
      </div>
    </Popup>
  );
}

export default withTranslation()(View);

View.defaultProps = {
  error: undefined,
  delay: undefined,
};
