import i18next, { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import Image from '../../components/image';
import styles from './index.module.scss';
import Button from '../../components/button';

interface ViewProps {
  t: TFunction;
  onContinue?: VoidFunction;
}

function View(props: ViewProps): JSX.Element {
  const { t, onContinue } = props;

  return (
    <div className={clsx('mobileOnly', styles.container)}>
      <div>
        <Image
          src={
            i18next.exists('mobile-only.background_image.src')
              ? t('mobile-only.background_image.src')
              : ''
          }
        />
        {onContinue && (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              text={t('mobile-only.continue')}
              onClick={onContinue}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default withTranslation()(View);

View.defaultProps = {
  onContinue: undefined,
};
