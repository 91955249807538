// Fetch country list

import CountryError from './errors';
import Log from '../log';

/** Get countries, and return a promise. */
export default class Countries {
  static detected: CountryInfo | undefined;

  // Get countries, and return a promise.
  static async get(): Promise<CountryInfo[]> {
    const data = await import('country-codes-list');
    const { all } = data;
    const items: CountryInfo[] = [];
    const countries = all();
    countries.forEach(({ countryCode, countryNameEn, countryCallingCode }) => {
      // Create country object
      const info = {
        code: countryCode.toLowerCase(),
        name: countryNameEn,
        label: countryCode,
        phonePrefix: countryCallingCode ? `+${countryCallingCode}` : '',
      };

      // Add it to array
      items.push(info);
    });
    // Sort array
    items.sort((a, b) => a.name.localeCompare(b.name));
    return items;
  }

  // Detect the current user's country info
  static async detect(): Promise<CountryInfo | undefined> {
    // Return if already detected
    if (Countries.detected) {
      return Countries.detected;
    }

    // Load items
    const countries = await Countries.get();

    // Fetch our country code
    const response = await fetch('https://geoip.blockv.io/json/');
    const data = await response.json();

    // Find matching item
    const country = countries.find((c) => c.code.toLowerCase() === data.country_code.toLowerCase());

    // Set it as our detected country
    if (country) {
      Countries.detected = country;
      Log.info('Detected country: ', country);
    }

    // Done
    return country;
  }

  /**
   * Detect the current user's country info.
   * @returns Promise which resolves with a country code
   */
  static async detectIPRegistry(apiKey: string): Promise<CountryInfo | null> {
    // Return if already detected
    if (Countries.detected) {
      return Countries.detected;
    }

    // Check if an API key has been provided.
    if (!apiKey) {
      Log.warn('IPService: Missing API Key');
      return Promise.resolve(null);
    }

    // Load items
    const countries = await Countries.get();

    // Fetch country code from ip-based service
    const result = await fetch(
      `https://api.ipregistry.co/?key=${apiKey}&fields=location.country.code`
    )
      .then(async (response) => {
        // Check for error
        if (!response.ok) {
          const text = await response.text();
          throw new CountryError(text, 'IPServiceFailed');
        }
        return response.json();
      })
      .catch((error) => {
        Log.error(`IPService error: ${error}`);
        throw error;
      });

    const code = result.location.country.code.toLowerCase();

    // Find matching item
    const countryInfo = countries.find((c) => c.code.toLowerCase() === code);

    // Set it as our detected country
    if (countryInfo) {
      Countries.detected = countryInfo;
      Log.info('IPService: Detected country info: ', countryInfo);
    } else {
      throw new CountryError(`Unable to find country info for code ${code}`, 'InfoLookupFailed');
    }

    return countryInfo;
  }
}

export interface CountryInfo {
  code: string;
  name: string;
  label: string;
  phonePrefix: string;
}
