// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.USVgcBOox_uvbGMS3iV0{margin:auto}`, "",{"version":3,"sources":["webpack://./src/features/error/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA","sourcesContent":[".codeContainer {\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeContainer": `USVgcBOox_uvbGMS3iV0`
};
export default ___CSS_LOADER_EXPORT___;
