import React, { Component } from 'react';
import Presenter from './presenter';
import Model from './model';
import View from './view';
import { Config } from '../../../types/config';
import { NotificationType } from '../../../types/vlabs-user';
import { FormattedToken } from '../../../types/common';

interface IndexProps {
  config: Config;
  notificationType: NotificationType | null;
  formattedToken: FormattedToken;
}

export default class LoginLink extends Component<IndexProps> {
  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const model = new Model(this);
    this.presenter = new Presenter(model);
  }

  render(): JSX.Element {
    const { notificationType, formattedToken, config } = this.props;
    return (
      <View
        {...this.state}
        presenter={this.presenter}
        notificationType={notificationType}
        formattedToken={formattedToken}
        config={config}
      />
    );
  }
}
