import React, { Component } from 'react';
import Model, { ErrorModelType } from './model';
import View from './view';
import Presenter from './presenter';
import Alert from '../../util/alert';
import { VlabsSDKError } from '../../manager/vlabs-errors';

export interface IndexProps {
  error: VlabsSDKError;
  onClose: VoidFunction;
  delay: number | undefined | null;
  open: boolean;
}

export default class ErrorPopup extends Component<IndexProps, ErrorModelType> {
  static showDefault(onClose?: VoidFunction): void {
    Alert.show(
      (id: string, close: VoidFunction) => (open: boolean) =>
        ErrorPopup.createPopup(id, open, { code: -1 }, close, onClose)
    );
  }

  static show(error: VlabsSDKError, onClose?: VoidFunction, delay?: number): void {
    Alert.show(
      (id: string, close: VoidFunction) => (open: boolean) =>
        ErrorPopup.createPopup(id, open, error, close, onClose, delay)
    );
  }

  static createPopup(
    id: string,
    open: boolean,
    error: VlabsSDKError,
    close: VoidFunction,
    onClose?: VoidFunction,
    delay?: number
  ): JSX.Element {
    return (
      <ErrorPopup
        key={`error-${id}`}
        open={open}
        error={error}
        delay={delay}
        onClose={(): void => {
          onClose?.();
          close();
        }}
      />
    );
  }

  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { error, onClose, delay } = props;
    this.presenter = new Presenter(new Model(this, error), error, delay, onClose);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(): void {
    const { onClose } = this.props;
    this.presenter.onClose = onClose;
  }

  render(): JSX.Element {
    const { open } = this.props;
    return <View {...this.state} presenter={this.presenter} open={open} />;
  }
}
