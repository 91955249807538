import React from 'react';
import clsx from 'clsx';
import BaseButton from '../button';
import WalletConnectIcon from './wc-logo-blue.svg';
import styles from './index.module.scss';

export default function Button(props: IndexProps): JSX.Element {
  const { className, text, disabled, onClick } = props;
  return (
    <BaseButton
      className={clsx(className, styles.button)}
      text={text}
      icon={WalletConnectIcon}
      disabled={disabled}
      onClick={onClick}
      variant="auth"
    />
  );
}
interface IndexProps {
  className?: string;
  text?: string | null;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

Button.defaultProps = {
  className: undefined,
  text: undefined,
  disabled: false,
  onClick: undefined,
};
