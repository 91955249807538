/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import Model, { StateModel } from './model';
import View from './view';
import Presenter from './presenter';
import Alert from '../../util/alert';
import { Translator } from '../../types/common';

export interface IndexProps {
  title: string | undefined;
  continueButtonTitle: string;
  closeButtonTitle: string;
  isContinueEnabled: boolean;
  open: boolean;
  onContinue: VoidFunction;
  onClose: VoidFunction;
  delay: number | undefined;
  body?: Translator;
  children?: React.ReactElement;
}

class Popup extends Component<IndexProps> {
  static defaultProps = {
    body: undefined,
    children: undefined,
  };

  static show(
    title: string | undefined,
    continueButtonTitle: string,
    closeButtonTitle: string,
    isContinueEnabled: boolean,
    onContinue: VoidFunction,
    onClose: VoidFunction,
    delay: number | undefined,
    body?: Translator,
    children?: React.ReactElement
  ): void {
    Alert.show(
      (id: string, close: VoidFunction) =>
        (open: boolean): JSX.Element =>
          Popup.createPopup(
            id,
            open,
            delay,
            title,
            continueButtonTitle,
            closeButtonTitle,
            isContinueEnabled,
            onContinue,
            onClose,
            close,
            body,
            children
          )
    );
  }

  static createPopup(
    id: string,
    open: boolean,
    delay: number | undefined,
    title: string | undefined,
    continueButtonTitle: string,
    closeButtonTitle: string,
    isContinueEnabled: boolean,
    onContinue: VoidFunction,
    onClose: VoidFunction,
    close: VoidFunction,
    body?: Translator,
    children?: React.ReactElement
  ): JSX.Element {
    return (
      <Popup
        key={`error-${id}`}
        open={open}
        delay={delay}
        title={title}
        body={body}
        continueButtonTitle={continueButtonTitle}
        closeButtonTitle={closeButtonTitle}
        isContinueEnabled={isContinueEnabled}
        onContinue={(): void => {
          close();
          onContinue();
        }}
        onClose={(): void => {
          close();
          onClose();
        }}
      >
        {children && children}
      </Popup>
    );
  }

  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { onContinue: onOK, onClose, delay } = props;
    this.presenter = new Presenter(new Model(this), delay, onOK, onClose);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(): void {
    const { onClose } = this.props;
    this.presenter.onClose = onClose;
  }

  render(): JSX.Element {
    const {
      title,
      body,
      continueButtonTitle,
      closeButtonTitle,
      isContinueEnabled,
      open,
      children,
    } = this.props;

    const child = children ?? null;

    return (
      <View
        title={title}
        body={body}
        continueButtonTitle={continueButtonTitle}
        closeButtonTitle={closeButtonTitle}
        isContinueEnabled={isContinueEnabled}
        {...(this.state as StateModel)}
        presenter={this.presenter}
        open={open}
      >
        {child}
      </View>
    );
  }
}

export default Popup;
