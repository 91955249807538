/* eslint-disable import/no-cycle */

import { ThemeButtonSize, ThemeButtonVariant, ThemeStyle } from '../../types/theme';

export default function createStyles(config: ThemeStyle): string[] {
  const styles: string[] = [];

  if (config.button?.size) {
    // Create size classes
    const sizes: [string, ThemeButtonSize][] = Object.entries(config.button.size);
    sizes.forEach(([key, value]) => {
      const {
        font_family: fontFamily,
        font_size: fontSize,
        font_color: fontColor,
        font_weight: fontWeight,
        border_thickness: borderThickness,
        border_radius: borderRadius,
        border_style: borderStyle,
        padding_top: paddingTop,
        padding_bottom: paddingBottom,
        padding_left: paddingLeft,
        padding_right: paddingRight,
        margin_top: marginTop,
        margin_bottom: marginBottom,
        margin_left: marginLeft,
        margin_right: marginRight,
        width,
        height,
      } = value;

      let rules = '';

      if (width && width?.length > 0) {
        rules += `width:${width};`;
      }

      if (height && height?.length > 0) {
        rules += `height:${height};`;
      }

      if (borderThickness && borderThickness?.length > 0) {
        rules += `border-width:${borderThickness};`;
      }
      if (borderRadius && borderRadius?.length > 0) {
        rules += `border-radius:${borderRadius};`;
      }
      if (borderStyle && borderStyle?.length > 0) {
        rules += `border-style:${borderStyle};`;
      }
      if (paddingTop && paddingTop?.length > 0) {
        rules += `padding-top:${paddingTop};`;
      }
      if (paddingBottom && paddingBottom?.length > 0) {
        rules += `padding-bottom:${paddingBottom};`;
      }
      if (paddingLeft && paddingLeft?.length > 0) {
        rules += `padding-left:${paddingLeft};`;
      }
      if (paddingRight && paddingRight?.length > 0) {
        rules += `padding-right:${paddingRight};`;
      }
      if (marginTop && marginTop?.length > 0) {
        rules += `margin-top:${marginTop};`;
      }
      if (marginBottom && marginBottom?.length > 0) {
        rules += `margin-bottom:${marginBottom};`;
      }
      if (marginLeft && marginLeft?.length > 0) {
        rules += `margin-left:${marginLeft};`;
      }
      if (marginRight && marginRight?.length > 0) {
        rules += `margin-right:${marginRight};`;
      }

      styles.push(`.base-button-${key} {${rules}}`);

      // Add rules to button's label

      rules = '';

      if (fontFamily && fontFamily?.length > 0) {
        rules += `font-family:${fontFamily};`;
      }
      if (fontSize && fontSize?.length > 0) {
        rules += `font-size:${fontSize};`;
      }
      if (fontColor && fontColor?.length > 0) {
        rules += `color:${fontColor};`;
      }
      if (fontWeight && fontWeight?.length > 0) {
        rules += `font-weight:${fontWeight};`;
      }

      styles.push(`.base-button-${key} > .button-label {${rules}}`);
    });
  }

  // Create variant classes
  if (config.button?.variant) {
    const variants: [string, ThemeButtonVariant][] = Object.entries(config.button.variant);
    variants.forEach(([key, value]) => {
      const {
        active_background_color: activeBackgroundColor,
        inactive_background_color: inactiveBackgroundColor,
        active_font_color: activeFontColor,
        inactive_font_color: inactiveFontColor,
        active_border_color: activeBorderColor,
        inactive_border_color: inactiveBorderColor,
      } = value;

      let rules = '';
      if (activeBackgroundColor && activeBackgroundColor.length > 0) {
        rules += `background-color:${activeBackgroundColor};`;
      }
      if (activeBorderColor && activeBorderColor.length > 0) {
        rules += `border-color:${activeBorderColor}`;
      }
      styles.push(`.base-button-${key}-active {${rules}}`);

      rules = '';
      if (activeFontColor && activeFontColor.length > 0) {
        rules += `color:${activeFontColor};`;
      }
      styles.push(`.base-button-${key}-active > .button-label {${rules}}`);

      rules = '';
      if (inactiveBackgroundColor && inactiveBackgroundColor.length > 0) {
        rules += `background-color:${inactiveBackgroundColor};`;
      }
      if (inactiveBorderColor && inactiveBorderColor.length > 0) {
        rules += `border-color:${inactiveBorderColor}`;
      }
      styles.push(`.base-button-${key}-inactive {${rules}}`);

      rules = '';
      if (inactiveFontColor && inactiveFontColor.length > 0) {
        rules += `color:${inactiveFontColor};`;
      }
      styles.push(`.base-button-${key}-inactive > .button-label {${rules}}`);
    });
  }
  return styles;
}
