import clsx from 'clsx';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import OtpInput from '../../components/otp-input';
import Button from '../../components/button';
import Label from '../../components/label';
import Presenter from './presenter';
import styles from './view.module.scss';
import { StateModel } from './model';
import { FormattedToken, LocalePrefix } from '../../types/common';
import ViewBase from '../view-base';

interface ViewProps extends StateModel {
  t: TFunction;
  presenter: Presenter;
  formattedToken: FormattedToken;
  numInputs: number;
  localePrefix: LocalePrefix;
}

function View(props: ViewProps): JSX.Element {
  const {
    t,
    presenter,
    otp,
    error,
    formattedToken,
    loading,
    isContinueEnabled,
    numInputs,
    localePrefix,
  } = props;

  let headerText = '';
  if (formattedToken.type === 'email') {
    headerText = t(`${localePrefix}.otp.header_text_email`);
  } else if (formattedToken.type === 'phone') {
    headerText = t(`${localePrefix}.otp.header_text_phone`);
  }

  let errorText = '';
  if (error === 'invalid_otp') {
    errorText = t(`${localePrefix}.otp.error_invalid_otp`);
  }

  return (
    <ViewBase prefix="view-otp" headerText={headerText}>
      <div className={clsx('view-otp-inner-container', styles.innerContainer)}>
        <Label
          className={clsx('view-otp-text', styles.text)}
          text={t(`${localePrefix}.otp.text`, { token: formattedToken?.value })}
          variant="body1"
        />
        <Label
          className={clsx('view-otp-resend-text', styles.text, styles.resend)}
          text={t(`${localePrefix}.otp.resend_text`, { token: formattedToken?.value })}
          onClick={(): Promise<void> => presenter.onResendClick()}
        />
        <div style={{ height: '40px' }} />

        <OtpInput
          className={clsx('view-otp-input-label', styles.input)}
          style={undefined}
          label={t(`${localePrefix}.otp.input_label`)}
          helperText={errorText}
          disabled={false}
          required={false}
          numInputs={numInputs}
          value={otp}
          onChange={(otpValue: string): void => presenter.onOtpChange(otpValue)}
        />
        <div className={styles.spacer1} />
        <Button
          className={clsx('view-otp-button', styles.button)}
          text={
            loading
              ? t(`${localePrefix}.landing.loading_text`)
              : t(`${localePrefix}.otp.button_text`)
          }
          loading={loading}
          disabled={!isContinueEnabled}
          onClick={(): Promise<void> => presenter.onContinueClick()}
        />
        <div className={styles.spacer5} />
      </div>
    </ViewBase>
  );
}

export default withTranslation()(View);
