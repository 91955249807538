/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';
import Label from '../../../../../components/label';

export interface DateIndexProps {
  className?: string;
  style?: any;
  date: any;
  disabled?: boolean;
  order?: string;
  onClick?: () => void;
  helperText: string;
}

export default function Input(props: DateIndexProps): JSX.Element {
  const { className, style, date, disabled, order = 'mdy', onClick, helperText } = props;

  const dayOrder = order.indexOf('d');
  const monthOrder = order.indexOf('m');
  const yearOrder = order.indexOf('y');
  let day = '';
  let month = '';
  let year = '';

  if (date) {
    day = date.date();
    month = date.month() + 1;
    year = date.year();
  }
  // Render button
  return (
    <div
      style={style}
      className={clsx(className, 'date-input-container', styles.container, disabled && 'disabled')}
      onClick={(): void => onClick?.()}
    >
      <Label
        className={clsx('date-input-helper-text', styles.helperText, 'h5')}
        text={helperText}
        color="warning"
        variant="subtitle2"
      />
      <div className={clsx('date-input-inner-container', styles.innerContainer)}>
        <div
          style={{ order: dayOrder }}
          className={clsx(
            'date-input-field',
            styles.input,
            !day && styles.placeholder,
            !day && 'date-input-field-placeholder'
          )}
        >
          {day || 'DD'}
        </div>
        <div
          style={{ order: monthOrder }}
          className={clsx(
            'date-input-field',
            styles.input,
            !month && styles.placeholder,
            !month && 'date-input-field-placeholder'
          )}
        >
          {month || 'MM'}
        </div>
        <div
          style={{ order: yearOrder }}
          className={clsx(
            'date-input-field',
            styles.input,
            !year && styles.placeholder,
            !year && 'date-input-field-placeholder'
          )}
        >
          {year || 'YYYY'}
        </div>
      </div>
    </div>
  );
}

Input.defaultProps = {
  className: undefined,
  order: undefined,
  onClick: undefined,
  style: undefined,
  disabled: false,
};
