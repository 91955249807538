import { Component } from 'react';
import BaseModel, { createModel } from '../../util/mvp/base-model';

export type WafCaptchaModel = {
  error: string;
};

export default function createWafCaptchaModel(
  component: Component
): BaseModel<WafCaptchaModel> & WafCaptchaModel {
  return createModel<WafCaptchaModel>(component, {
    error: '',
  });
}
