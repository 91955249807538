import { FontStyle, ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  // landing page overrides
  const { background_color: overrideBackgroundColor, typography } =
    theme.override?.landing?.mobile ?? {};

  const styles: string[] = [];

  if (overrideBackgroundColor && overrideBackgroundColor.length > 0) {
    styles.push(`.login-landing-container {background-color: ${overrideBackgroundColor};}`);
  }

  if (typography) {
    const entries: [string, FontStyle][] = Object.entries(typography);
    entries.forEach(([key, value]) => {
      let rules = '';

      if (value.font_family && value.font_family.length > 0) {
        rules += `font-family:${value.font_family};`;
      }
      if (value.font_size && value.font_size?.length > 0) {
        rules += `font-size:${value.font_size};`;
      }
      if (value.font_weight && value.font_weight?.length > 0) {
        rules += `font-weight:${value.font_weight};`;
      }
      if (value.font_color && value.font_color?.length > 0) {
        rules += `color:${value.font_color};`;
      }

      styles.push(`.login-landing-mobile-typography.${key} {${rules}}`);
    });
  }

  if (typography?.body1) {
    styles.push(
      `.login-landing-mobile-line-override {background-color: ${typography.body1.font_color}}`
    );
  }

  if (theme.top_margin) {
    styles.push(`.login-landing-background-image-container {height: ${theme.top_margin}}`);
    styles.push(`.base-background-image-container {height: ${theme.top_margin}}`);
  }

  return styles;
}
