import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryView from '../../views/view-error-boundary';
import getAllUrlParams from '../../util/get-url-params';
import Page from '../../features/claim';
import App from '../../app';
import GeoBlocker from '../../features/geo-blocker/view';
import Log from '../../util/log';
import { Config } from '../../types/config';
import { PageProps } from '../../types/common';

const removeLoader = (): void => {
  const loader = document.getElementById('loader');
  if (loader) {
    setTimeout(() => {
      loader.classList.add('fade-out');
      setTimeout(() => {
        if (loader.parentNode) {
          loader.parentNode.removeChild(loader);
        }
      }, 2000);
    }, 300);
  }
};

function ClaimMultiPage(props: PageProps): JSX.Element | null {
  const { match, location, config } = props || {};
  const { params } = match;
  let { hash } = location;
  if (hash.startsWith('#/')) {
    hash = hash.replace('#/', '');
  } else if (hash.startsWith('#')) {
    hash = hash.replace('#', '');
  }
  const searchParams = getAllUrlParams(location.search);

  useEffect(() => {
    // Check if claim page is archived
    if (config.claim.archived) {
      Log.warn('Claim page is archived. Redirecting to "/".');
      window.location.replace('/');
      removeLoader();
    }
  }, []);

  const projectId = config.wallet_connect?.project_id;
  if (!projectId) {
    Log.warn('Missing WalletConnect Cloud Project Id', projectId);
  }

  let page: () => JSX.Element;
  let ethereumClient: EthereumClient | undefined;

  const innerPage = (
    <Page
      {...{
        ...params,
        ...searchParams,
        config,
        hash,
        removeLoader,
      }}
    />
  );

  page = (): JSX.Element => innerPage;

  if (projectId) {
    const chains = [arbitrum, mainnet, polygon];

    // Wagmi config
    const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
    const wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId, chains }),
      publicClient,
    });

    // Web3Modal Ethereum Client
    ethereumClient = new EthereumClient(wagmiConfig, chains);

    page = (): JSX.Element => <WagmiConfig config={wagmiConfig}>{innerPage}</WagmiConfig>;
  }

  return (
    <>
      {projectId && projectId.length > 0 && (
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      )}
      <GeoBlocker config={config} availableComponent={page} onComplete={removeLoader} />
    </>
  );
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <App path="./" page="claim-multi">
    {(config: Config): JSX.Element => {
      return (
        <Router>
          <Switch>
            <ErrorBoundary FallbackComponent={ErrorBoundaryView}>
              {config && (
                <Route
                  key="claim-multi"
                  component={(props: PageProps): JSX.Element | null =>
                    ClaimMultiPage({ ...props, config })
                  }
                />
              )}
            </ErrorBoundary>
          </Switch>
        </Router>
      );
    }}
  </App>
);
