import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const styles: string[] = [];

  const successColor = theme.palette?.success;

  let rules = '';
  if (successColor && successColor?.length > 0) {
    rules += `border-color:${successColor};`;
  }
  styles.push(`.otp-input-style:focus {${rules}}`);

  // landing page overrides
  const {
    background_color: backgroundColor,
    border_radius: borderRadius,
    border_color: borderColor,
    border_width: borderWidth,
    border_style: borderStyle,
  } = theme.input ?? {};

  rules = '';

  if (backgroundColor && backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor && borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
  }
  if (borderWidth && borderWidth?.length > 0) {
    rules += `border-width:${borderWidth};`;
  }
  if (borderStyle && borderStyle?.length > 0) {
    rules += `border-style:${borderStyle};`;
  }
  if (borderRadius) {
    rules += `border-radius:${borderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(`.otp-input-style {${rules}}`);
  }

  return styles;
}
