// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MQJaZhtZJ9t8pXNZeT9I{box-sizing:border-box;width:100%;min-height:100%;display:flex;flex-direction:column;align-items:center;position:relative}.G3axBqT0HTZYoQt5Xe0I{box-sizing:border-box;display:flex;flex-direction:column;align-items:center;padding-top:24px;padding-left:34px;padding-right:34px;width:100%}.hRWoclNCTGa82lLyBGDi{position:absolute;left:34px;top:24px}.Dj9FFzaOLiSc_fJCrq_l{text-align:center;margin-top:8px;margin-bottom:24px;font-size:20px;font-weight:600}.CIO7AV3JRKojXDuZleG_{text-align:center;margin-bottom:24px}.dDoyECOXeQq5Y2fbxKP4{flex:1 1 auto}.nNdldwj_xLQofZDW_zy_{flex:5 1 auto}.qawcA96qxQiqzN8UAOmi{text-align:center}`, "",{"version":3,"sources":["webpack://./src/views/view-wallet-connect/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,aAAA,CAGF,sBACE,iBAAA","sourcesContent":[".container {\n  box-sizing: border-box;\n  width: 100%;\n  min-height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n\n.innerContainer {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 24px;\n  padding-left: 34px;\n  padding-right: 34px;\n  width: 100%;\n}\n\n.backButton {\n  position: absolute;\n  left: 34px;\n  top: 24px;\n}\n\n.headerText {\n  text-align: center;\n  margin-top: 8px;\n  margin-bottom: 24px;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.text {\n  text-align: center;\n  margin-bottom: 24px;\n}\n\n.spacer1 {\n  flex: 1 1 auto;\n}\n\n.spacer5 {\n  flex: 5 1 auto;\n}\n\n.button {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MQJaZhtZJ9t8pXNZeT9I`,
	"innerContainer": `G3axBqT0HTZYoQt5Xe0I`,
	"backButton": `hRWoclNCTGa82lLyBGDi`,
	"headerText": `Dj9FFzaOLiSc_fJCrq_l`,
	"text": `CIO7AV3JRKojXDuZleG_`,
	"spacer1": `dDoyECOXeQq5Y2fbxKP4`,
	"spacer5": `nNdldwj_xLQofZDW_zy_`,
	"button": `qawcA96qxQiqzN8UAOmi`
};
export default ___CSS_LOADER_EXPORT___;
