import { ThemeStyle } from '../types/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const { palette } = theme;

  const {
    primary,
    success,
    info,
    warning,
    error,
    backdrop: backdropColor,
    background: backgroundColor,
  } = palette ?? {};

  const root: HTMLElement | null = document.querySelector(':root');

  const styles = [];

  if (primary) {
    styles.push(`.palettePrimary {background-color: ${primary};}`);
  }

  if (success) {
    styles.push(`.paletteSuccess {background-color: ${success};}`);
  }

  if (info) {
    styles.push(`.paletteInfo {background-color: ${info};}`);
  }

  if (warning) {
    styles.push(`.paletteWarning {background-color: ${warning};}`);
  }

  if (error) {
    styles.push(`.paletteError {background-color: ${error};}`);
  }

  if (backdropColor) {
    styles.push(`.paletteBackdrop {background-color: ${backdropColor};}`);
  }

  if (backgroundColor) {
    styles.push(`.paletteBackground {background-color: ${backgroundColor};}`);
  }

  // Loader

  if (primary) {
    root?.style.setProperty('--loader-color', primary);
  }

  if (palette?.backdrop) {
    root?.style.setProperty('--loader-background-color', palette?.backdrop);
  }

  // Overrides

  if (theme.override?.loader) {
    const { foreground: loaderForeground, background: loaderBackground } = theme.override.loader;

    if (loaderForeground) {
      root?.style.setProperty('--loader-color', loaderForeground);
    }

    if (loaderBackground) {
      root?.style.setProperty('--loader-background-color', loaderBackground);
    }
  }

  return styles;
}
