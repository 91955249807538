import dayjs from 'dayjs';
import ErrorPopup from '../error';
import WafCaptchaPopup from '../waf-captcha';
import Log from '../../util/log';
import Model, { ClaimPage } from './model';
import { Config, ConsentType, CustomInputItem } from '../../types/config';
import { FormattedToken, AuthProvider } from '../../types/common';
import { VLabsConfig, VLabsParams } from '../../types/vlabs-user';

export default class Presenter {
  model: Model;

  config: Config;

  removeLoader: VoidFunction | null;

  hashSetup: boolean;

  supported_locales: string[];

  constructor(model: Model, config: Config, removeLoader: VoidFunction) {
    this.model = model;
    this.config = config;
    this.supported_locales = config.supported_locales;
    this.removeLoader = removeLoader;
    this.hashSetup = false;
  }

  onAttach(): void {
    if (this.config && !this.hashSetup) {
      this.setup();
      this.hashSetup = true;
    }

    const baseLanguage = navigator.language.split('-')[0];

    // Use the browser's language, otherwise use the base language (e.g. 'en' for 'en-GB'), otherwise fallback onto the config's default language, otherwise 'en'.
    if (this.supported_locales?.includes(navigator.language)) {
      this.onLanguageChange(navigator.language);
    } else if (this.supported_locales?.includes(baseLanguage)) {
      this.onLanguageChange(baseLanguage);
    } else {
      const defaultLanguage = this.config.default_language;
      let defaultLocale = 'en';
      if (typeof defaultLanguage === 'string') {
        defaultLocale = defaultLanguage;
      } else if (Array.isArray(defaultLanguage) && defaultLanguage.length > 0) {
        [defaultLocale] = defaultLanguage;
      }
      this.onLanguageChange(defaultLocale);
    }

    // Ensure the selected language has associated consent policies
    if (!this.config.claim.consent.policies[this.model.language]) {
      throw new Error(`Consent policies don't contain selected language ${this.model.language}`);
    }
  }

  update(hash: string, config: Config): void {
    this.model.hash = hash;
    this.model.config = config;
    this.config = config;
    if (this.config && !this.hashSetup) {
      this.setup();
      this.hashSetup = true;
    }
  }

  onMobileFirstContinueClick(): void {
    this.model.showMobileOnly = false;
  }

  onNext(authProvider: AuthProvider, currentPage?: ClaimPage): void {
    this.model.authProvider = authProvider;
    if (authProvider === 'token') {
      this.moveToPage(ClaimPage.capture);
    } else if (authProvider === 'walletConnect') {
      if (currentPage === ClaimPage.landing) {
        this.moveToPage(ClaimPage.signChallenge);
      } else if (currentPage === ClaimPage.signChallenge) {
        this.moveToPage(ClaimPage.capture);
      } else {
        Log.error('Unrecognized flow. Current page:', currentPage);
      }
    } else {
      const params: VLabsParams = {
        language: this.model.language,
        consent: 1,
      };

      // Get all policies for the presented language
      const languageSpecificPolicies = this.config.claim.consent.policies[this.model.language];

      // Consent Framework
      if (this.model.acceptedPrimaryConsent) {
        // Build accepted policies
        const accepted = languageSpecificPolicies;

        // Remove marketing if enabled but not accepted
        if (
          this.config.claim.consent.marketing?.enabled === true &&
          !this.model.acceptedMarketingConsent
        ) {
          delete accepted.marketing;
        }

        params.policies_accepted = accepted;
      }

      // Consent Old
      if (this.config.claim.consent.marketing?.enabled === true) {
        params.optin = this.model.acceptedMarketingConsent ? 'yes' : 'no';
      }

      if (authProvider === 'facebook') {
        params.connect = 'facebook';
      } else if (authProvider === 'google') {
        params.connect = 'google';
      } else if (authProvider === 'outside') {
        params.connect = 'outside';
      }

      // Initiate OAuth flow
      VlabsUser.go(
        '/signup',
        params,
        (_, notificationType) => {
          this.onRegister?.(authProvider, notificationType);

          // Move to capture screen.
          this.onNext(authProvider, currentPage);
        },
        (error) => {
          const { delay: errorDelay, redirect_on_go: redirectOnGoError } = this.config.error;
          ErrorPopup.show(error, () => {}, redirectOnGoError && errorDelay);
          Log.error(error);
        }
      ).then((response): void => {
        Log.info(`[Debug] .go('/signup') response:`, response);
      });
    }
  }

  onBack(): void {
    this.model.acceptedPrimaryConsent = false;
    this.model.acceptedMarketingConsent = false;
    this.model.isConsentPopupContinueEnabled = false;
    this.moveToPage(ClaimPage.landing);
  }

  onRegister(
    authProvider: AuthProvider,
    notificationType: string,
    formattedToken: FormattedToken | undefined = undefined
  ): void {
    Log.info(`[Debug] onRegister:
      authProvider: ${authProvider},
      notificationType: ${notificationType},
      formattedToken: ${formattedToken}
      token_verification: ${this.config.token_verification}
    `);
    if (authProvider === 'token' && formattedToken) {
      Log.info(`[Debug] onRegister: moving to page "otp" or "link"`);
      this.model.formattedToken = formattedToken;
      const pageIndex = this.config.token_verification === 'otp' ? ClaimPage.otp : ClaimPage.link;

      this.model.values = {
        notificationType,
        index: pageIndex,
      };
    } else {
      Log.info('[Debug] onRegister: moving to page "capture"');
      this.model.values = {
        notificationType,
        index: ClaimPage.capture,
      };
    }
  }

  setup(): void {
    const {
      app_id: appId,
      vlabs_api_url: vlabsApiUrl,
      vlabs_aws_waf_threat_detection_url: awsWafThreatDetectionUrl,
      vlabs_aws_waf_captcha_url: awsWafCaptchaUrl,
      vlabs_aws_waf_captcha_api: awsWafCaptchaApi,
      aws_waf_threat_detection_url: awsWafThreatDetectionUrlBackCompat,
      environment,
      version,
      key,
      campaign,
      domain,
      ga_tracking_id: ga,
      facebook_app_id: facebook,
      google_app_id: google,
      outside_app_id: outside,
      session_bypass: sessionBypass,
      claim,
      pixel,
      error: errorConfig,
      platform_api_url: platformApiUrl,
      request_applet: requestAppletConfig,
    } = this.config;

    const {
      tradr_visit_pixel: tradrVisitPixel,
      tradr_visit_advertiser: tradrVisitAdvertiser,
      tradr_signup_pixel: tradrSignupPixel,
      tradr_signup_advertiser: tradrSignupAdvertiser,
      pixel_performance_visit: pixelPerformanceVisit,
      pixel_performance_signup: pixelPerformanceSignup,
    } = pixel;

    const { hostname } = window.location;
    if (domain && hostname !== domain) {
      const url = new URL(
        `https://${domain}${window.location.pathname}${window.location.hash}${window.location.search}`
      );
      window.location.replace(url);
      return;
    }

    const { delay: errorDelay, redirect_on_load: redirectOnLoadError } = errorConfig;

    if (claim?.expired) {
      this.moveToPage(ClaimPage.expired);
      this.removeLoader?.();
      return;
    }
    const subDomain = window.location.hostname.split('.')[0];
    const appletConfig = {
      ...requestAppletConfig,
      url: `https://${subDomain}.ra.smartmedialabs.io`,
      onready: (error?: Error): void => {
        if (error) {
          ErrorPopup.show({ code: 'request_applet_init_error' }, () => {
            window.location.reload();
          });
        }
      },
    };

    const vlabsConfig: VLabsConfig = {
      app_id: appId,
      vlabs_api_url: vlabsApiUrl,
      aws_waf_threat_detection_url: awsWafThreatDetectionUrl ?? awsWafThreatDetectionUrlBackCompat,
      aws_waf_captcha:
        (awsWafCaptchaUrl &&
          awsWafCaptchaApi && {
            url: awsWafCaptchaUrl,
            api: awsWafCaptchaApi,
            onrequest: () => WafCaptchaPopup.show(awsWafCaptchaApi),
          }) ||
        undefined,
      blueprint: 'claim-multi',
      environment,
      version,
      campaign,
      ga,
      facebook_app: facebook,
      google_app: google,
      outside_app: outside,
      session_bypass: sessionBypass,
      pixel_gtm: pixel?.gtm,
      pixel_facebook: pixel?.facebook,
      pixel_twitter: pixel?.twitter,
      pixel_visit: pixel?.visit,
      pixel_signup: pixel?.signup,
      tradr_visit_pixel: tradrVisitPixel,
      tradr_visit_advertiser: tradrVisitAdvertiser,
      tradr_signup_pixel: tradrSignupPixel,
      tradr_signup_advertiser: tradrSignupAdvertiser,
      pixel_performance_visit: pixelPerformanceVisit,
      pixel_performance_signup: pixelPerformanceSignup,
      request_applet: appletConfig?.enabled ? appletConfig : undefined,
      platform_api_url: platformApiUrl,
    };

    VlabsUser.init({
      key,
      config: vlabsConfig,
    });

    VlabsUser.load(
      () => {
        const { email, phone } = VlabsUser.query || {};
        const userToken = email || phone;
        if (userToken) {
          this.model.inputToken = userToken;
        }
        if (this.config.age.min && !this.model.dateOfBirth) {
          this.moveToPage(ClaimPage.ageGateLanding);
        } else {
          this.moveToPage(ClaimPage.landing);
        }
        this.removeLoader?.();
        this.removeLoader = null;
      },
      () => {
        this.removeLoader?.();
        this.removeLoader = null;
      },
      (error) => {
        ErrorPopup.show(error, () => {}, redirectOnLoadError && errorDelay);
        this.removeLoader?.();
        this.removeLoader = null;
        Log.error(error);
      },
      (info) => {
        Log.info('[Debug] func onAuthComplete called with:', info);
        if (info.authProvider === 'token') {
          // SDK will redirect to viewer
          this.removeLoader?.();
          this.removeLoader = null;
        } else {
          // Map SDK's provider to web-viewers provider
          if (info.authProvider === 'facebook') {
            this.model.authProvider = 'facebook';
          } else if (info.authProvider === 'google') {
            this.model.authProvider = 'google';
          } else if (info.authProvider === 'eth') {
            this.model.authProvider = 'walletConnect';
          }
          // Flag that auth has already been completed
          this.model.isAuthComplete = true;

          // Populate the input name field from the social info
          const socialName = `${info.firstName ?? ''} ${info.lastName ?? ''}`.trim();
          this.model.socialName = socialName;
          if (claim.name_type) {
            switch (claim.name_type) {
              case 'first_name': {
                this.model.inputName = info.firstName ?? '';
                break;
              }
              case 'last_name':
                this.model.inputName = info.lastName ?? '';
                break;
              case 'full_name':
                this.model.inputName = socialName;
                break;
              default: {
                break;
              }
            }
          }
          this.model.avatarURL = info.avatarURL ?? null;
          this.moveToPage(ClaimPage.capture);
        }
        this.removeLoader?.();
        this.removeLoader = null;
      }
    );
  }

  // Inputs

  onTokenInputChange(value: string): void {
    this.model.inputToken = value;
  }

  onNameInputChange(value: string): void {
    this.model.inputName = value;
  }

  onAdditionalChange(value: string): void {
    this.model.inputAdditional = value;
  }

  onCustomInputChange(value: string, custom: CustomInputItem): void {
    // eslint-disable-next-line no-param-reassign
    custom.value = value;
    // eslint-disable-next-line no-self-assign
    this.model.inputCustom = this.model.inputCustom;
  }

  onConsentChange(type: ConsentType, consent: boolean): void {
    if (type === 'primary') {
      this.model.acceptedPrimaryConsent = consent;
      if (this.config.claim.consent?.default?.enabled) {
        this.model.isConsentPopupContinueEnabled = consent;
      } else {
        this.model.isConsentPopupContinueEnabled = true;
      }
    } else if (type === 'marketing') {
      this.model.acceptedMarketingConsent = consent;
    }
  }

  onAgeGatePassed(date: any): void {
    const dateOfBirth = dayjs(date).format('YYYY-MM-DD');
    this.model.dateOfBirth = dateOfBirth;
    this.moveToPage(ClaimPage.landing);
  }

  onAgeGateFailed(): void {
    this.moveToPage(ClaimPage.ageGateError);
  }

  onLanguageChange(language: string): void {
    this.model.language = language;
  }

  moveToPage(page: ClaimPage): void {
    this.model.index = page;
  }
}
