import React, { Component } from 'react';
import { AuthProvider, FormattedToken } from '../../../types/common';
import { Config, ConsentType, CustomInputItem } from '../../../types/config';
import Model, { StateModel } from './model';
import Presenter from './presenter';
import View from './view';

interface IndexProps {
  config: Config;
  authProvider: AuthProvider;
  inputToken: string;
  onTokenChange: (token: string) => void;
  avatarURL: string | null;
  socialName: string | null;
  inputName: string;
  onNameChange: (name: string) => void;
  inputCustom: CustomInputItem[] | null;
  onCustomChange: (name: string, custom: CustomInputItem) => void;
  inputAdditional: string | null;
  onAdditionalChange: (value: string) => void;
  language: string;
  dateOfBirth: string | null;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isAuthComplete: boolean;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
  onBack: VoidFunction;
  onRegister: (notificationType: string, formattedToken?: FormattedToken) => void;
}

export default class Index extends Component<IndexProps> {
  presenter: Presenter;

  authProvider: AuthProvider;

  constructor(props: IndexProps) {
    super(props);
    const {
      config,
      authProvider,
      inputToken,
      inputName,
      inputCustom,
      inputAdditional,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      language,
      dateOfBirth,
      isAuthComplete,
      onConsentChange,
      onBack,
      onRegister,
    } = props;
    this.authProvider = authProvider;
    const model = new Model(this);
    this.presenter = new Presenter(
      model,
      config,
      inputToken,
      inputName,
      inputCustom,
      inputAdditional,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      language,
      dateOfBirth,
      isAuthComplete,
      onConsentChange,
      onBack,
      onRegister
    );
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(prevProps: IndexProps): void {
    const {
      inputToken,
      inputName,
      inputAdditional,
      inputCustom,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      isAuthComplete,
    } = this.props;

    if (prevProps !== this.props) {
      this.presenter.inputToken = inputToken;
      this.presenter.inputName = inputName;
      this.presenter.inputAdditional = inputAdditional;
      this.presenter.inputCustom = inputCustom;
      this.presenter.acceptedPrimaryConsent = acceptedPrimaryConsent;
      this.presenter.acceptedMarketingConsent = acceptedMarketingConsent;
      this.presenter.isAuthComplete = isAuthComplete;
    }
  }

  render(): JSX.Element {
    const {
      inputToken,
      onTokenChange,
      socialName,
      inputName,
      onNameChange,
      avatarURL,
      inputCustom,
      onCustomChange,
      inputAdditional,
      onAdditionalChange,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      onConsentChange,
      isAuthComplete,
      language,
      config,
    } = this.props;

    const languagePolicies = config.claim.consent.policies[language];
    return (
      <View
        inputToken={inputToken}
        onTokenChange={onTokenChange}
        socialName={socialName}
        inputName={inputName}
        onNameChange={onNameChange}
        avatarURL={avatarURL}
        inputCustom={inputCustom}
        onCustomChange={onCustomChange}
        inputAdditional={inputAdditional}
        onAdditionalChange={onAdditionalChange}
        acceptedPrimaryConsent={acceptedPrimaryConsent}
        acceptedMarketingConsent={acceptedMarketingConsent}
        onConsentChange={onConsentChange}
        {...(this.state as StateModel)}
        presenter={this.presenter}
        authProvider={this.authProvider}
        isAuthComplete={isAuthComplete}
        languagePolicies={languagePolicies}
      />
    );
  }
}
