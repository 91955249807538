import { Component } from 'react';

export type ModelMap = Record<string, any>;
export type ModelKey<T extends ModelMap> = string & keyof T;

export interface Model<T extends ModelMap> {
  set<K extends ModelKey<T>>(key: K, value: T[K]): void;
  setAll(values: Partial<T>): void;
  get<K extends ModelKey<T>>(key: K): T[K];
  getAll(): T;
}

export default class BaseModel<T extends ModelMap> implements Model<T> {
  private component: Component;

  private internalState: T;

  constructor(component: Component, initialState: T) {
    this.component = component;
    this.component.state = initialState;
    this.internalState = { ...initialState };
  }

  set<K extends ModelKey<T>>(key: K, value: T[K]): void {
    this.component.setState({ [key]: value });
    this.internalState[key] = value;
  }

  setAll(values: Partial<T>): void {
    this.component.setState({ ...values });
    this.internalState = { ...this.internalState, ...values };
  }

  get<K extends ModelKey<T>>(key: K): T[K] {
    const value = this.internalState[key as string];
    return value as T[K];
  }

  getAll(): T {
    return { ...this.internalState } as T;
  }
}

export function createModel<T extends ModelMap>(
  component: Component,
  initialState: T
): BaseModel<T> & T {
  const model = new BaseModel<T>(component, initialState);
  Object.keys(initialState).forEach((key) => {
    if (!(key in model)) {
      const self = model;
      Object.defineProperty(self, key, {
        get() {
          return self.get(key);
        },
        set(value) {
          self.set(key, value);
        },
      });
    }
  });
  return model as BaseModel<T> & T;
}
