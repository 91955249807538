import React, { PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import clsx from 'clsx';
import Button from '../button';
import Label from '../label';
import styles from './view.module.scss';
import { StateModel } from './model';
import Presenter from './presenter';
import { Translator } from '../../types/common';

interface ViewProps extends StateModel {
  title: string | undefined;
  body?: Translator;
  continueButtonTitle: string;
  closeButtonTitle: string;
  isContinueEnabled: boolean;
  open: boolean;
  t: TFunction;
  presenter: Presenter;
}

function View(props: PropsWithChildren<ViewProps>): JSX.Element {
  const {
    presenter,
    open,
    t,
    title,
    body,
    continueButtonTitle,
    closeButtonTitle,
    isContinueEnabled,
    children,
  } = props;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={(): void => presenter.onCloseClick()}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div className={clsx(styles.content, 'popup-content')}>
        <div className={clsx(styles.container, 'popup-container')}>
          <div className={clsx(styles.innerContainer, 'popup-inner-container')}>
            <div className={clsx(styles.textContainer, 'popup-text-container')}>
              {title && title.length > 0 && (
                <Label
                  className={clsx(styles.titleText, 'popup-title-text')}
                  text={t(title)}
                  variant="subtitle1"
                />
              )}

              {body && (
                <Label
                  className={clsx(styles.bodyText, 'popup-body-text')}
                  text={body(t)}
                  variant="body2"
                />
              )}
            </div>

            {children && (
              <div className={clsx(styles.childrenContainer, 'popup-children-container')}>
                {children}
              </div>
            )}

            <div className={clsx(styles.buttonContainer, 'popup-button-container')}>
              <Button
                className={clsx(styles.buttonClose, 'popup-button-close')}
                text={t(closeButtonTitle)}
                onClick={(): void => {
                  presenter.onCloseClick();
                }}
                variant="secondary"
                size="small"
              />
              {continueButtonTitle.length > 0 && (
                <Button
                  className={clsx(
                    styles.buttonContinue,
                    'popup-button-continue',
                    !isContinueEnabled && styles.buttonDisabled
                  )}
                  text={t(continueButtonTitle)}
                  disabled={!isContinueEnabled}
                  onClick={(): void => {
                    if (isContinueEnabled) {
                      presenter.onContinueClick();
                    }
                  }}
                  variant="primary"
                  size="small"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

View.defaultProps = {
  body: undefined,
};

export default withTranslation()(View);
