// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gwdDDodehdzjqecWJWtP{display:flex;flex-direction:row;align-items:center;justify-content:center;padding:10px 0px;border-color:#333;border-style:solid;border-width:2px;background-color:#fdd100;border-radius:6px;cursor:pointer;margin:20px}.gwdDDodehdzjqecWJWtP>img{width:auto;height:30px;margin-left:8px;margin-right:6px}.gwdDDodehdzjqecWJWtP>div{font-size:18px;font-weight:900;text-align:right;color:#000}.KD2PZ4VJUtCX7ZtrShk0{max-width:50px;height:100%;flex:1}.tSzB8kFPSnRPbIegxRPM{cursor:default}`, "",{"version":3,"sources":["webpack://./src/components/button-outside/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CACA,wBAAA,CACA,iBAAA,CACA,cAAA,CACA,WAAA,CACA,0BACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,gBAAA,CAEF,0BACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CAIJ,sBACE,cAAA,CACA,WAAA,CACA,MAAA,CAEF,sBACE,cAAA","sourcesContent":[".button {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 10px 0px;\n  border-color: #333;\n  border-style: solid;\n  border-width: 2px;\n  background-color: #fdd100;\n  border-radius: 6px;\n  cursor: pointer;\n  margin: 20px;\n  > img {\n    width: auto;\n    height: 30px;\n    margin-left: 8px;\n    margin-right: 6px;\n  }\n  > div {\n    font-size: 18px;\n    font-weight: 900;\n    text-align: right;\n    color: #000;\n  }\n}\n\n.space {\n  max-width: 50px;\n  height: 100%;\n  flex: 1;\n}\n.disabled {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `gwdDDodehdzjqecWJWtP`,
	"space": `KD2PZ4VJUtCX7ZtrShk0`,
	"disabled": `tSzB8kFPSnRPbIegxRPM`
};
export default ___CSS_LOADER_EXPORT___;
