import React from 'react';
import clsx from 'clsx';
import i18next, { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import Label from '../../../components/label';
import styles from './view.module.scss';
import EmailButton, { TokenAuthOption } from '../../../components/button-token';
import FacebookButton from '../../../components/button-facebook';
import GoogleButton from '../../../components/button-google';
import OutsideButton from '../../../components/button-outside';
import WCButton from '../../../components/button-walletconnect';
import Presenter from './presenter';
import { StateModel } from './model';
import ConsentPopup from '../consent-popup';
import { ConsentType, LanguagePolicies } from '../../../types/config';
import Source from '../../../components/source';
import Image from '../../../components/image';

interface ViewProps extends StateModel {
  t: TFunction;
  presenter: Presenter;
  isConsentPopupOpen: boolean;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isConsentPopupContinueEnabled: boolean;
  languagePolicies: LanguagePolicies;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
}

function View(props: ViewProps): JSX.Element {
  const isSmall = useMediaQuery('(max-width: 856px)');

  const {
    t,
    presenter,
    loading,
    showFacebook,
    showGoogle,
    showOutsideOauth,
    showWalletConnect,
    oauthOnly,
    allowEmail,
    allowPhone,
    isConsentPopupOpen,
    isConsentPopupContinueEnabled,
    advertiserName,
    showPrimaryConsent,
    showMarketingConsent,
    marketingConsentTerms,
    acceptedPrimaryConsent,
    acceptedMarketingConsent,
    languagePolicies,
    onConsentChange,
  } = props;

  let authOption: TokenAuthOption | null;
  let tokenButtonText = '';

  const is1stPartyAuthAvailable = !oauthOnly && (allowEmail || allowPhone);

  if (allowEmail && allowPhone) {
    authOption = 'emailOrPhone';
    tokenButtonText = i18next.exists('claim.landing.token_button_email_phone')
      ? t('claim.landing.token_button_email_phone')
      : '';
  } else if (allowEmail) {
    authOption = 'email';
    tokenButtonText = i18next.exists('claim.landing.token_button_email')
      ? t('claim.landing.token_button_email')
      : '';
  } else if (allowPhone) {
    authOption = 'phone';
    tokenButtonText = i18next.exists('claim.landing.token_button_phone')
      ? t('claim.landing.token_button_phone')
      : '';
  }

  return (
    <>
      <div
        className={clsx(
          isSmall ? 'claim-landing-container' : 'claim-landing-container-anchored',
          styles.container
        )}
      >
        {isSmall && (
          <>
            <video
              className={clsx('claim-landing-background-video-container', styles.videoContainer)}
              autoPlay
              muted
              loop
            >
              <Source
                type="video/mp4"
                className={clsx('claim-landing-background-video', styles.video)}
              />
            </video>
            <div
              className={clsx('claim-landing-background-image-container', styles.imageContainer)}
            >
              <Image
                alt=""
                src={
                  i18next.exists('claim.landing.background_image.src')
                    ? t('claim.landing.background_image.src')
                    : ''
                }
                className={clsx('claim-landing-background-image', styles.image)}
              />
            </div>
          </>
        )}
        <div className={clsx('claim-landing-inner-container', styles.innerContainer)}>
          <Label
            className={clsx(
              isSmall ? 'claim-landing-text' : 'claim-landing-text-anchored',
              styles.welcomeText,
              isSmall ? 'claim-landing-mobile-typography' : null
            )}
            text={t('claim.landing.text')}
            variant="h5"
          />
          <div className={clsx('claim-landing-header-space', styles.headerSpace)} />
          <Label
            className={clsx(
              isSmall ? 'claim-landing-context-text' : 'claim-landing-context-text-anchored',
              isSmall ? styles.contextText : styles.contextTextAnchored,
              isSmall ? 'claim-landing-mobile-typography' : null
            )}
            text={t('claim.landing.context_text')}
            variant="body1"
          />{' '}
          {is1stPartyAuthAvailable && (
            <EmailButton
              option={authOption!}
              className={clsx(
                isSmall ? 'claim-landing-token' : 'claim-landing-token-anchored',
                styles.button
              )}
              text={tokenButtonText}
              disabled={loading}
              onClick={(): void => presenter.onAuthProviderClick('token')}
            />
          )}
          <div className={clsx('claim-landing-social-container', styles.socialContainer)}>
            {showFacebook && (
              <FacebookButton
                className={clsx('claim-landing-facebook', styles.facebook)}
                text={t('claim.landing.facebook')}
                disabled={loading}
                onClick={(): void => presenter.onAuthProviderClick('facebook')}
              />
            )}
            {showGoogle && (
              <GoogleButton
                className={clsx('claim-landing-google', styles.google)}
                text={t('claim.landing.google')}
                disabled={loading}
                onClick={(): void => presenter.onAuthProviderClick('google')}
              />
            )}
            {showOutsideOauth && (
              <OutsideButton
                className={clsx('claim-landing-outside', styles.outsideOauth)}
                text={t('claim.landing.outside_oauth')}
                disabled={loading}
                onClick={(): void => presenter.onAuthProviderClick('outside')}
              />
            )}
            {showWalletConnect && (
              <WCButton
                className={clsx('claim-landing-button-wallet-connect')}
                text={t('claim.landing.wallet_connect')}
                disabled={loading}
                onClick={(): void => presenter.onAuthProviderClick('walletConnect')}
              />
            )}
          </div>
          <div className={clsx('claim-landing-footer-space', styles.footerSpace)} />
          <Label
            className={clsx(
              isSmall ? 'claim-landing-footer' : 'claim-landing-footer-anchored',
              styles.footer,
              isSmall ? 'claim-landing-mobile-typography' : null
            )}
            text={t('claim.landing.footer')}
            variant="footer1"
          />
        </div>
      </div>

      <ConsentPopup
        open={isConsentPopupOpen}
        isContinueEnabled={isConsentPopupContinueEnabled}
        onContinue={(): void => presenter.onConsentPopupContinue()}
        onClose={(): void => presenter.closeConsentPopup()}
        advertiserName={advertiserName}
        showPrimaryConsent={showPrimaryConsent}
        showMarketingConsent={showMarketingConsent}
        marketingConsentTerms={marketingConsentTerms}
        marketingChecked={acceptedMarketingConsent}
        primaryChecked={acceptedPrimaryConsent}
        onPrimaryChange={(checked: boolean): void => {
          onConsentChange('primary', checked);
        }}
        onMarketingChange={(checked: boolean): void => {
          onConsentChange('marketing', checked);
        }}
        languagePolicies={languagePolicies}
      />
    </>
  );
}

export default withTranslation()(View);
