import { ThemeStyle, FontStyle } from '../../types/theme';

export default function createStyles(config: ThemeStyle): string[] {
  const styles: string[] = [];

  const { typography, palette } = config;

  if (typography) {
    const entries: [string, FontStyle][] = Object.entries(typography);
    entries.forEach(([key, value]) => {
      let rules = '';

      if (value.font_family && value.font_family.length > 0) {
        rules += `font-family:${value.font_family};`;
      }
      if (value.font_size && value.font_size?.length > 0) {
        rules += `font-size:${value.font_size};`;
      }
      if (value.font_weight && value.font_weight?.length > 0) {
        rules += `font-weight:${value.font_weight};`;
      }
      if (value.font_color && value.font_color?.length > 0) {
        rules += `color:${value.font_color};`;
      }

      styles.push(`.label.${key} {${rules}}`);

      if (palette?.link) {
        const { unvisited, visited, hover, active } = palette.link || {};

        if (unvisited && unvisited?.length > 0) {
          styles.push(`.label.${key} > a:link { color: ${unvisited}}`);
        }

        if (visited && visited?.length > 0) {
          styles.push(`.label.${key} > a:visited { color: ${visited}}`);
        }

        if (hover && hover?.length > 0) {
          styles.push(`.label.${key} > a:hover { color: ${hover}}`);
        }

        if (active && active?.length > 0) {
          styles.push(`.label.${key} > a:active { color: ${active}}`);
        }
      }
    });
  }

  if (palette) {
    if (palette.primary) {
      styles.push(`.label.primary { color: ${palette.primary}; }`);
    }

    if (palette.success) {
      styles.push(`.label.success { color: ${palette.success}; }`);
    }

    if (palette.info) {
      styles.push(`.label.info { color: ${palette.info}; }`);
    }

    if (palette.warning) {
      styles.push(`.label.warning { color: ${palette.warning}; }`);
    }

    if (palette.error) {
      styles.push(`.label.error { color: ${palette.error}; }`);
    }
  }

  return styles;
}
