/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Button from '../../../components/button';
import DateInput from './components/date-input';
import styles from './view.module.scss';
import { StateModel } from './model';
import Presenter from './presenter';
import BaseView from '../../../views/view-base';

interface ViewProps extends StateModel {
  t: TFunction;
  presenter: Presenter;
  format: string;
}

function View(props: ViewProps): JSX.Element {
  const { t, selectedDate, presenter, format, errorText } = props;
  const [showDatePicker, setShowDatePicker] = useState(false);

  let muiDateFormat = 'MM/DD/YYYY';
  if (format === 'dmy') {
    muiDateFormat = 'DD/MM/YYYY';
  }

  return (
    <BaseView
      prefix="age-landing"
      headerText={t('age.landing.input_label')}
      footerText={t('claim.landing.footer')}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          open={showDatePicker}
          openTo="year"
          disableFuture
          onClose={(): void => setShowDatePicker(false)}
          value={selectedDate}
          inputFormat={muiDateFormat}
          onChange={(newValue): void => presenter.onBirthdayChange(newValue)}
          onAccept={(newValue): void => presenter.onBirthdaySelected(newValue)}
          renderInput={(renderProps): JSX.Element => {
            if (renderProps.inputProps?.onChange) {
              return <TextField {...renderProps} color="primary" />;
            }
            return (
              <DateInput
                className={clsx('age-landing-date', errorText && 'error')}
                helperText={errorText && t(errorText)}
                date={selectedDate}
                order={format}
                onClick={(): void => setShowDatePicker(true)}
              />
            );
          }}
        />
      </LocalizationProvider>
      <div className={styles.spacer1} />
      <Button
        className={clsx('age-landing-button', styles.button)}
        text={t('age.landing.button_text')}
        onClick={(): void => presenter.onNextClick()}
      />
      <div className={styles.spacer2} />
    </BaseView>
  );
}

export default withTranslation()(View);
