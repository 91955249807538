import log, { LoggingMethod } from 'loglevel';
import { sanitizeInput, sanitizeObject } from './sanitization';

const originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName): LoggingMethod => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);

  return (...args) => {
    const sanitizedArgs = args.map((arg) => {
      if (arg instanceof Date) {
        return sanitizeInput(arg.toString());
      }
      if (arg instanceof Error) {
        return sanitizeInput(arg.toString());
      }
      if (typeof arg === 'string') {
        return sanitizeInput(arg);
      }
      if (typeof arg === 'object' && arg !== null) {
        return sanitizeObject(arg);
      }

      return arg;
    });
    rawMethod(...sanitizedArgs);
  };
};
// Be sure to call setLevel method in order to apply plugin
log.setLevel(log.getLevel());

log.setDefaultLevel(log.levels.DEBUG);
class Log {
  debug(...args: any[]): void {
    log.debug(...args);
  }

  info(...args: any[]): void {
    log.info(...args);
  }

  warn(...args: any[]): void {
    log.warn(...args);
  }

  error(...args: any[]): void {
    log.error(...args);
  }
}

export default new Log();
