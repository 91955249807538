/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import clsx from 'clsx';
import { useElementSize } from 'usehooks-ts';
import styles from './index.module.scss';
import Label from '../label';
import { ButtonSize, ButtonVariant } from '../../types/theme';
import LoadingLightIcon from './loading-light.png';
import LoadingDarkIcon from './loading-dark.png';

export default function Button(props: IndexProps): JSX.Element {
  const {
    className,
    disabled,
    loading,
    onClick,
    text,
    icon,
    variant,
    size,
    labelStyle,
    iconStyle,
  } = props;

  const [squareRef, { width }] = useElementSize();

  const hasText = !!(text && text.length > 0);
  const smallerThanThreshold = width < 250;
  const iconOnly = !hasText || (smallerThanThreshold && icon);

  const loadingClassName = size === 'large' ? styles.loadingImageLarge : styles.loadingImageSmall;

  return (
    // eslint-disable-next-line react/button-has-type
    <div
      ref={squareRef}
      className={clsx(
        className,
        `base-button-${size}`,
        !disabled && `base-button-${variant}-active`,
        disabled && `base-button-${variant}-inactive`,
        styles.button,
        loading && 'loading',
        disabled && 'disabled',
        !disabled && styles.buttonActive,
        disabled && styles.disabled,
        loading && styles.loading
      )}
      onClick={(event): void => {
        if (!disabled) onClick?.(event);
      }}
    >
      {loading && (
        <img
          className={clsx(loadingClassName, iconStyle)}
          alt=""
          src={variant === 'light' ? LoadingDarkIcon : LoadingLightIcon}
        />
      )}
      {!loading && (
        <>
          <div className={iconOnly ? styles.iconOnlyContainer : styles.iconContainer}>
            <img
              className={clsx(`button-icon`, disabled ? styles.iconDisabled : styles.icon)}
              alt=""
              src={icon}
            />
          </div>
          {!iconOnly && (
            <Label className={clsx('button-label', styles.label, labelStyle)} text={text} />
          )}
        </>
      )}
    </div>
  );
}

interface IndexProps {
  className?: string;
  labelStyle?: string | React.CSSProperties;
  iconStyle?: string | React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  text?: string | null;
  icon?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

Button.defaultProps = {
  className: undefined,
  labelStyle: undefined,
  iconStyle: undefined,
  disabled: false,
  loading: false,
  text: undefined,
  icon: undefined,
  variant: 'primary',
  size: 'large',
  onClick: undefined,
};
