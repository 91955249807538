// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SoKksJCFDVQwA1IksNqm{width:100%;padding-left:0px;padding-right:0px;text-align:center}.aESVfpoZgeNez2T4eJ8F{text-align:center}.AB3NAZggEZ1vLOKaUmSF{flex:1 1 auto}.WYxJDPIOeWd18g_dna7g{flex:2 1 auto}.FxtSCPxBfYhac2mIR90f{margin-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/features/age-gate/landing/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,aAAA,CAGF,sBACE,kBAAA","sourcesContent":[".button {\n  width: 100%;\n  padding-left: 0px;\n  padding-right: 0px;\n  text-align: center;\n}\n\n.input {\n  text-align: center;\n}\n\n.spacer1 {\n  flex: 1 1 auto;\n}\n\n.spacer2 {\n  flex: 2 1 auto;\n}\n\n.header {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `SoKksJCFDVQwA1IksNqm`,
	"input": `aESVfpoZgeNez2T4eJ8F`,
	"spacer1": `AB3NAZggEZ1vLOKaUmSF`,
	"spacer2": `WYxJDPIOeWd18g_dna7g`,
	"header": `FxtSCPxBfYhac2mIR90f`
};
export default ___CSS_LOADER_EXPORT___;
