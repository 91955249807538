// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BPvxQuPWhJ2Zp6NVkC9j{box-sizing:border-box;display:flex;align-items:center;justify-content:center;width:100%;height:100%}.pnAqxq9ZKVhQolUrPXQ7{box-sizing:border-box;display:flex;align-items:center;flex-direction:column;min-width:250px;max-width:300px;gap:22px}._uEoLJkuqGhm0FWD5qmI{text-align:center}`, "",{"version":3,"sources":["webpack://./src/views/view-error-boundary/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,qBAAA,CACA,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,eAAA,CACA,eAAA,CACA,QAAA,CAGF,sBACE,iBAAA","sourcesContent":[".container {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n\n.innerContainer {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  min-width: 250px;\n  max-width: 300px;\n  gap: 22px;\n}\n\n.text {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BPvxQuPWhJ2Zp6NVkC9j`,
	"innerContainer": `pnAqxq9ZKVhQolUrPXQ7`,
	"text": `_uEoLJkuqGhm0FWD5qmI`
};
export default ___CSS_LOADER_EXPORT___;
