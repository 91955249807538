import React, { Component } from 'react';
import { FormattedToken, LocalePrefix } from '../../types/common';
import Model, { StateModel } from './model';
import Presenter, { OtpContinueHandler, OtpResendHandler } from './presenter';
import View from './view';

interface IndexProps {
  localePrefix: LocalePrefix;
  formattedToken: FormattedToken;
  onBack: VoidFunction;
  onOtpResend: OtpResendHandler;
  onOtpContinue: OtpContinueHandler;
}

export default class Index extends Component<IndexProps> {
  presenter: Presenter;

  numInputs = 6;

  constructor(props: IndexProps) {
    super(props);
    const { formattedToken, onOtpResend, onOtpContinue } = props;
    this.presenter = new Presenter(
      new Model(this),
      formattedToken,
      this.numInputs,
      onOtpResend,
      onOtpContinue
    );
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(): void {}

  render(): JSX.Element {
    const { formattedToken, localePrefix } = this.props;
    return (
      <View
        {...(this.state as StateModel)}
        presenter={this.presenter}
        formattedToken={formattedToken}
        numInputs={this.numInputs}
        localePrefix={localePrefix}
      />
    );
  }
}
