import React, { Component } from 'react';
import Presenter from './presenter';
import Model from './model';
import View from './view';
import { Config } from '../../../types/config';

interface IndexProps {
  config: Config;
}

export default class ClaimExpired extends Component<IndexProps> {
  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    this.presenter = new Presenter(new Model(this, {}));
  }

  render(): JSX.Element {
    const { config } = this.props;
    return <View {...this.state} presenter={this.presenter} config={config} />;
  }
}
