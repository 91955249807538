import React, { Component } from 'react';
import Presenter from './presenter';
import Model, { StateModel } from './model';
import View from './view';
import { Config, ConsentType } from '../../../types/config';
import { AuthProvider } from '../../../types/common';

interface IndexProps {
  config: Config;
  language: string;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isConsentPopupContinueEnabled: boolean;
  onNext: (authProvider: AuthProvider) => void;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
}

export default class ClaimMultiLanding extends Component<IndexProps> {
  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { config, onNext, onConsentChange } = props;
    this.presenter = new Presenter(new Model(this), config, onNext, onConsentChange);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(): void {}

  render(): JSX.Element {
    const {
      config,
      language,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      isConsentPopupContinueEnabled,
      onConsentChange,
    } = this.props;

    const languagePolicies = config.claim.consent.policies[language];
    return (
      <View
        {...(this.state as StateModel)}
        acceptedPrimaryConsent={acceptedPrimaryConsent}
        acceptedMarketingConsent={acceptedMarketingConsent}
        isConsentPopupContinueEnabled={isConsentPopupContinueEnabled}
        onConsentChange={onConsentChange}
        presenter={this.presenter}
        languagePolicies={languagePolicies}
      />
    );
  }
}
