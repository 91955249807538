/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';
import OutsideIcon from './outside-logo.svg';

export default function Button(props: IndexProps): JSX.Element {
  const { className, disabled, onClick, text } = props;
  // Render button
  return (
    <div
      className={clsx(
        className,
        'outside-button',
        disabled && 'disabled',
        styles.button,
        disabled && styles.disabled
      )}
      onClick={(event): void | boolean => !disabled && onClick?.(event)}
    >
      <div className="outside-button-label">{text}</div>
      <img className="outside-button-icon" alt="" src={OutsideIcon} />
    </div>
  );
}

export interface IndexProps {
  className?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  text: string;
}

Button.defaultProps = {
  className: undefined,
  disabled: false,
};
