/**
 * Cleans the input by manually replacing all CRLF characters whilst preserving the intent for auditing.
 *
 * See CWE 117 (“Log Poisoning”): https://community.veracode.com/s/article/How-to-Fix-CWE-117-Improper-Output-Neutralization-for-Logs
 *
 * @param input Input
 * @returns Sanitized string.
 */
export function sanitizeInput(input: string): string {
  return input.replace(/[\n]/g, '\\n').replace(/[\r]/g, '\\r');
}

function internalSanitizeObject(
  obj: Record<string, any>,
  stack: Set<any> = new Set()
): Record<string, any> {
  const sanitizedObj: Record<string, any> = {};
  stack.add(obj);
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'string') {
      sanitizedObj[key] = sanitizeInput(value);
    } else if (typeof value === 'object' && value !== null) {
      if (!stack.has(value)) {
        sanitizedObj[key] = internalSanitizeObject(value, new Set(stack));
      }
    } else {
      sanitizedObj[key] = value;
    }
  });

  return sanitizedObj;
}

/**
 * Cleans the object by manually replacing all CRLF characters whilst preserving the intent for auditing.
 *
 * @param obj Object
 * @returns Sanitized object.
 */
export function sanitizeObject(obj: Record<string, any>): Record<string, any> {
  return internalSanitizeObject(obj);
}
