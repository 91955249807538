import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';
import BackIcon from './back-icon.svg';

interface IndexProps {
  className: string;
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function Button(props: IndexProps): JSX.Element {
  const { className, disabled, onClick } = props;
  // Render button
  return (
    <button
      type="button"
      className={clsx(
        className,
        'button-back',
        disabled && 'disabled',
        styles.button,
        disabled && styles.disabled
      )}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void | boolean =>
        !disabled && onClick?.(event)
      }
    >
      <img className="back-button-icon" alt="" src={BackIcon} />
    </button>
  );
}
