import i18n, { InitOptions, Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import LocaleEn from './resources/locales/en.json';
import AlertProvider from './util/alert/alert-provider';
import deepMerge from './util/deep-merge';
import loadScript from './util/load-script';
import Log from './util/log';
import createBaseViewStyles from './views/view-base/style-loader-theme';
import createButtonThemeStyles from './components/button/style-loader-theme';
import createCheckboxThemeStyle from './components/checkbox/style-loader-theme';
import createInputThemeStyle from './components/input/style-loader-theme';
import createTypographyThemeStyles from './components/label/style-loader-theme';
import createOTPInputThemeStyles from './components/otp-input/style-loader-theme';
import createSelectThemeStyle from './components/select/style-loader-theme';
import createAnchorViewThemeStyles from './views/view-anchor/style-loader-theme';
import createDateInputThemeStyle from './features/age-gate/landing/components/date-input/style-loader-theme';
import createClaimMultiThemeStyle from './features/claim/style-loader-theme';
import createGeoBlockerThemeStyle from './features/geo-blocker/style-loader-theme';
import createLoginMultiThemeStyle from './features/login/style-loader-theme';
import createUnsubscribeMultiThemeStyle from './features/unsubscribe/style-loader-theme';
import createThemeStyle from './util/style-loader-theme';
import { AppPage } from './types/common';
import { Config } from './types/config';
import { sanitizeCdnUrl } from './util/validation';
import './util/dompurifyConfig';

interface AppProps {
  path: string;
  page: AppPage;
  children: (config: Config) => JSX.Element;
}

export default class App extends React.Component<AppProps, { config?: Config }> {
  config?: Config;

  theme = createTheme({});

  constructor(props: AppProps) {
    super(props);
    // Setup state
    this.state = {};
  }

  componentDidMount(): void {
    const { path, page } = this.props;
    fetch(`${path}public/config/config.json`, { method: 'GET', cache: 'no-store' })
      .then((result) => result.json())
      .then((config) => {
        const {
          global_config_path: globalConfigPath,
          locales,
          default_language: defaultLanguage,
        } = config;
        if (locales !== undefined && (locales === null || Array.isArray(locales))) {
          // eslint-disable-next-line no-param-reassign
          config.locales = {};
        }
        if (
          defaultLanguage === null ||
          (Array.isArray(defaultLanguage) && defaultLanguage.length === 0) ||
          defaultLanguage === ''
        ) {
          // eslint-disable-next-line no-param-reassign
          delete config.default_language;
        }
        if (globalConfigPath) {
          return fetch(`${globalConfigPath}`, { method: 'GET', cache: 'no-store' })
            .then((result) => result.json())
            .catch(() => ({}))
            .then((globalConfig) => {
              return deepMerge(globalConfig, config);
            });
        }
        return config;
      })
      .then(async (config) => {
        const { lab_sdk_url: labSdkUrl } = config;

        // Load the vlabs sdk
        if (labSdkUrl && labSdkUrl.length > 0) {
          await loadScript(labSdkUrl);
          return config;
        }

        // Load fallback (live cdn)
        const fallbackUrl =
          page === 'unsubscribe'
            ? `https://cdn.smartmedialabs.io/s/js/vlabs-unsubscribe.js`
            : `https://cdn.smartmedialabs.io/s/js/vlabs-user.js`;

        Log.error(
          `Config Error: Missing 'lab_sdk_url'. Falling back on hardcoded url ${fallbackUrl}`
        );

        await loadScript(fallbackUrl);
        return config;
      })
      .then(async (config) => {
        // Cookie scripts
        try {
          const { environment } = config;
          let oneTrustApiKey = '';
          if (window.location.hostname.endsWith('.smartwallet.app')) {
            if (environment === 'alpha') {
              oneTrustApiKey = '9c9cf5af-db55-47da-bf82-3b80f627e7b2-test';
            } else {
              oneTrustApiKey = '9c9cf5af-db55-47da-bf82-3b80f627e7b2';
            }
          } else if (environment === 'alpha') {
            oneTrustApiKey = 'cd2e990d-f529-43a0-9d9f-5b2c05e96047-test';
          } else {
            oneTrustApiKey = 'cd2e990d-f529-43a0-9d9f-5b2c05e96047';
          }
          await Promise.all([
            loadScript(`https://cdn.cookielaw.org/consent/${oneTrustApiKey}/OtAutoBlock.js`),
            loadScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', undefined, {
              'data-domain-script': oneTrustApiKey,
            }),
          ]);
        } catch (error: any) {
          Log.error('CookieLaw script(s) load failed.', error);
        }

        return config;
      })
      .then(async (config): Promise<Config> => {
        // generate style sheet from config
        const { style } = config;
        const { external, theme } = style;
        const sheet = document.createElement('style');
        document.body.appendChild(sheet);
        const styleSheet = sheet.sheet;
        if (styleSheet) {
          style.fonts?.forEach((font: any) => {
            styleSheet.insertRule(
              `@font-face {font-family: ${font?.family};src: url(${font.src});}`,
              styleSheet.cssRules.length
            );
          });

          if (theme) {
            createThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createTypographyThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createAnchorViewThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createBaseViewStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createButtonThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createOTPInputThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createInputThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createSelectThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createGeoBlockerThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createDateInputThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createCheckboxThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            // Page specific theme
            if (page === 'claim-multi') {
              createClaimMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            } else if (page === 'login-multi') {
              createLoginMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            } else if (page === 'unsubscribe-multi') {
              createUnsubscribeMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            }
          }

          if (external?.length > 0) {
            await Promise.all(
              external.map((stylePath: string) => {
                return new Promise<Config | void>((resolve, reject) => {
                  const sanitizedStylePath = sanitizeCdnUrl(stylePath);
                  if (sanitizedStylePath === undefined) {
                    reject(new Error(`Style URL is untrusted: ${stylePath}`));
                    return;
                  }
                  const stylesheet = document.createElement('link');
                  stylesheet.rel = 'stylesheet';
                  stylesheet.type = 'text/css';
                  stylesheet.href = sanitizedStylePath.toString();
                  const timeout = setTimeout(() => {
                    resolve(config);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                  }, 3000);
                  stylesheet.onload = (): void => {
                    clearTimeout(timeout);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                    resolve();
                  };
                  stylesheet.onerror = (loadError): void => {
                    Log.error(loadError);
                    clearTimeout(timeout);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                    resolve();
                  };
                  document.getElementsByTagName('head')[0].prepend(stylesheet);
                });
              })
            );
            return config;
          }
          return Promise.resolve(config);
        }
        Log.error('Config setup failed: Missing stylesheet');
        throw new Error('Config setup failed: Missing stylesheet');
      })
      .then((config) => {
        // Configure MUI theme

        const themePalette = config.style.theme?.palette;
        const themeButton = config.style.theme?.button;
        const themeInput = config.style.theme?.input;

        const muiTheme = createTheme({
          components: {
            // Buttons
            MuiButton: {
              styleOverrides: {
                root: {
                  color: themeButton?.variant?.primary.active_font_color ?? '#00000',
                  backgroundColor:
                    themeButton?.variant?.primary.active_background_color ?? '#00000',
                  borderRadius: themeButton?.size?.large.border_radius ?? '#00000',
                  padding: '4px 12px',
                  '&:hover': {
                    backgroundColor:
                      themeButton?.variant?.primary.active_background_color ?? '#00000',
                  },
                },
              },
            },
            // Inputs
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  borderRadius: themeInput?.border_radius ?? '0px',
                  borderColor: themeInput?.border_color ?? '#00000',
                },
              },
            },
          },
        });

        // Palette
        if (themePalette?.background && themePalette.background.length > 0) {
          muiTheme.palette.background.default = themePalette?.background;
        }
        if (themePalette?.info && themePalette.info.length > 0) {
          muiTheme.palette.info.main = themePalette?.info;
        }
        if (themePalette?.success && themePalette.success.length > 0) {
          muiTheme.palette.success.main = themePalette?.success;
        }
        if (themePalette?.error && themePalette.error.length > 0) {
          muiTheme.palette.error.main = themePalette?.error;
        }
        if (themePalette?.warning && themePalette.warning.length > 0) {
          muiTheme.palette.warning.main = themePalette?.warning;
        }

        this.theme = muiTheme;

        return Promise.resolve(config);
      })
      .then((config) => {
        this.config = config;
        const resources: Resource = {};
        const {
          locales = {},
          disable_built_in_language: disableBuiltInLanguage,
          supported_locales: supportedLocales,
          default_language: defaultLanguage,
        } = config;
        if (!disableBuiltInLanguage) {
          resources.en = { translation: LocaleEn };
        }

        let defaultLang: string | null = null;
        if (typeof defaultLanguage === 'string') {
          defaultLang = defaultLanguage;
        } else if (Array.isArray(defaultLanguage)) {
          [defaultLang] = defaultLanguage;
        }
        if (defaultLang && supportedLocales?.indexOf(defaultLang) === -1) {
          // eslint-disable-next-line no-param-reassign
          config.default_language = supportedLocales;
        }
        return Promise.all(
          // Map over each available languages in the config's locale section (generally set in the global config)
          Object.keys(locales).map(async (localeLngKey) => {
            // Don't fetch unsupported languages (but always fetch english)
            if (supportedLocales.indexOf(localeLngKey) === -1 && localeLngKey !== 'en') {
              return Promise.resolve();
            }

            const { external, overrides } = locales[localeLngKey];

            // Fetch external translation files
            if (external?.length > 0) {
              Log.info('[Languages] Fetching external', external);
              try {
                const result = await fetch(external);
                const externalJson = await result.json();
                const mergedTranslations = deepMerge(externalJson, overrides);
                resources[localeLngKey] = { translation: mergedTranslations };
                // Handle en special case
                if (localeLngKey === 'en') {
                  // Use en as the key-only fallback
                  resources.fb = resources.en;
                  // If en isn't supported, delete it
                  if (!supportedLocales.includes('en')) {
                    delete resources.en;
                  }
                }
              } catch {
                return {};
              }
            }
            return Promise.resolve();
          })
        ).then(() => {
          const fallbackLng = [...(this.config?.default_language ?? []), 'fb'];
          const options: InitOptions = {
            debug: true,
            fallbackLng,
            detection: {
              order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
            },
            resources,
          };
          i18n.use(LanguageDetector).use(initReactI18next).init(options);
          this.setState({ config: this.config });
        });
      })
      .catch((error) => {
        Log.error('App load error:', error);
      });
  }

  render(): JSX.Element {
    const { config } = this.state;
    const { children } = this.props;
    return (
      <ThemeProvider theme={this.theme}>
        <AlertProvider>{config && children?.(config)}</AlertProvider>
      </ThemeProvider>
    );
  }
}
