import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle, className = '.input-container'): string[] {
  const styles: string[] = [];

  const placeholder = theme.typography?.placeholder?.font_color;

  let rules = '';

  if (placeholder && placeholder?.length > 0) {
    rules += `color:${placeholder};`;
  }
  if (rules.length > 0) {
    styles.push(
      `${className} > .input-box > .input-field::-webkit-input-placeholder, ${className} > .input-box > .input-field::placeholder {${rules}}`
    );
  }

  // landing page overrides
  const {
    background_color: backgroundColor,
    border_radius: borderRadius,
    border_color: borderColor,
    border_width: borderWidth,
    border_style: borderStyle,
  } = theme.input ?? {};

  rules = '';

  if (backgroundColor && backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor && borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
  }
  if (borderWidth && borderWidth?.length > 0) {
    rules += `border-width:${borderWidth};`;
  }
  if (borderStyle && borderStyle?.length > 0) {
    rules += `border-style:${borderStyle};`;
  }
  if (borderRadius) {
    rules += `border-radius:${borderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(`${className} > .input-box {${rules}}`);
  }

  return styles;
}
