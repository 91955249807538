import React from 'react';
import clsx from 'clsx';
import ButtonBase from '../button';
import GoogleIcon from './google-logo-33.svg';
import styles from './index.module.scss';

export default function Button(props: IndexProps): JSX.Element {
  const { className, disabled, onClick, text } = props;

  return (
    <ButtonBase
      className={clsx(className, styles.button)}
      labelStyle={styles.label}
      disabled={disabled}
      onClick={onClick}
      text={text}
      icon={GoogleIcon}
      variant="auth"
    />
  );
}

export interface IndexProps {
  className?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  text: string;
}

Button.defaultProps = {
  className: undefined,
  disabled: false,
};
