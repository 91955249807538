import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Model from './model';
import Log from '../../../util/log';

dayjs.extend(isSameOrBefore);

export default class Presenter {
  model: Model;

  minAge: number;

  onSuccess: (date: any) => void;

  onError: () => void;

  constructor(model: Model, minAge: number, onSuccess: (date: any) => void, onError: () => void) {
    this.model = model;
    this.minAge = minAge;
    this.onSuccess = onSuccess;
    this.onError = onError;
  }

  onBirthdayChange(date: any): void {
    this.model.errorText = '';
    this.model.selectedDate = date;
  }

  onBirthdaySelected(date: any): void {
    Log.info('onBirthdaySelected date', date);
  }

  onNextClick(): void {
    const { selectedDate } = this.model;

    if (selectedDate) {
      const minDateOfBirth = dayjs().subtract(this.minAge, 'year');
      if (selectedDate.isSameOrBefore(minDateOfBirth)) {
        this.onSuccess?.(selectedDate);
      } else {
        this.onError?.();
      }
    } else {
      this.model.errorText = 'age.landing.error_invalid_date';
    }
  }
}
