import React, { useState } from 'react';

export default function Page(props) {
  const { children, current, number, className } = props;
  const [show, setShow] = useState(current === number);
  let left = '-100%';
  if (current < number) {
    left = '100%';
  } else if (current === number) {
    left = '0%';
  }
  if (!show && current === number) {
    setTimeout(() => {
      if (show !== (current === number)) {
        setShow(current === number);
      }
    }, (100 / 6) * 0.25);
  }
  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        top: 0,
        left,
        width: show ? '100%' : 0,
        height: '100%',
        transition: 'left 0.25s, opacity 0.25s',
        opacity: !show ? '0' : '1',
      }}
      onTransitionEnd={() => {
        setShow(current === number);
      }}
    >
      {show && children}
    </div>
  );
}
