import { VlabsSDKError } from '../../manager/vlabs-errors';
import Model from './model';

export default class Presenter {
  model: Model;

  onClose: VoidFunction;

  error: VlabsSDKError;

  delay: number | null | undefined;

  constructor(
    model: Model,
    error: VlabsSDKError,
    delay: number | null | undefined,
    onClose: VoidFunction
  ) {
    this.model = model;
    this.onClose = onClose;
    this.error = error;
    this.delay = delay;
    if (!error) {
      onClose?.();
    }
  }

  onAttach(): void {
    if (this.delay) {
      setTimeout(() => {
        window.location.reload();
        this.onClose?.();
      }, this.delay);
    }
  }

  onCloseClick(): void {
    this.onClose?.();
  }
}
