// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YO_mnjUmdNqQKVw7JZVJ{width:100%;height:100%;display:flex;flex-direction:column;align-items:center}.T4TwBW8n6IxxhPYsiI_A{display:flex;flex-direction:column;align-items:center;max-width:600px;width:100%;height:100%;box-sizing:border-box;position:relative;width:330px}.T4TwBW8n6IxxhPYsiI_A>*{z-index:1}.M885qr2ELvhj340DTsPu{width:100%;padding-left:0px;padding-right:0px;text-align:center}.mxTakaGofUklIyId61V5{overflow:visible}.diOym2HzlUcmovL7mYWE{width:100%;height:auto;object-fit:contain}.sw9VjKgRqP5QWuQcS3XY{object-fit:contain}.reFHppy1jF50m6bcMD9J{text-align:center}`, "",{"version":3,"sources":["webpack://./src/features/claim/expired/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,iBAAA,CACA,WAAA,CAGF,wBACE,SAAA,CAGF,sBACE,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CAMF,sBACE,kBAAA,CAGF,sBACE,iBAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.innerContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 600px;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  position: relative;\n  width: 330px;\n}\n\n.innerContainer > * {\n  z-index: 1;\n}\n\n.button {\n  width: 100%;\n  padding-left: 0px;\n  padding-right: 0px;\n  text-align: center;\n}\n\n.imageContainer {\n  overflow: visible;\n}\n\n.image {\n  width: 100%;\n  height: auto;\n  object-fit: contain;\n}\n\n.contextImageContainer {\n}\n\n.contextImage {\n  object-fit: contain;\n}\n\n.expiredText {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `YO_mnjUmdNqQKVw7JZVJ`,
	"innerContainer": `T4TwBW8n6IxxhPYsiI_A`,
	"button": `M885qr2ELvhj340DTsPu`,
	"imageContainer": `mxTakaGofUklIyId61V5`,
	"image": `diOym2HzlUcmovL7mYWE`,
	"contextImage": `sw9VjKgRqP5QWuQcS3XY`,
	"expiredText": `reFHppy1jF50m6bcMD9J`
};
export default ___CSS_LOADER_EXPORT___;
