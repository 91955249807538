import { CaptureAdditionalItem, NameType } from '../../../types/config';
import BaseModel from '../../../util/model';

export interface StateModel {
  advertiserName: string;
  allowEmail: boolean;
  allowPhone: boolean;
  captureAdditional: CaptureAdditionalItem[] | null;
  captureName: boolean;
  countryCode: string | null;
  disableCountryPicker: boolean;
  errorAdditional: any | null;
  errorEmail: any | null;
  errorName: any | null;
  errorPhone: any | null;
  errorPrimaryConsent: any | null;
  errorToken: any | null;
  loading: boolean;
  marketingConsentTerms: string;
  nameType: NameType | null;
  showPrimaryConsent: boolean;
  showCountryCode: boolean;
  showMarketingConsent: boolean;
}

export default class Model extends BaseModel implements StateModel {
  static initialState: StateModel = {
    advertiserName: '',
    allowEmail: false,
    allowPhone: false,
    captureAdditional: null,
    captureName: false,
    countryCode: null,
    disableCountryPicker: false,
    errorAdditional: null,
    errorEmail: null,
    errorName: null,
    errorPhone: null,
    errorPrimaryConsent: null,
    errorToken: null,
    loading: false,
    marketingConsentTerms: '',
    nameType: null,
    showPrimaryConsent: true,
    showCountryCode: false,
    showMarketingConsent: false,
  };

  constructor(component: React.Component) {
    const { initialState } = Model;
    super(component, initialState);
  }

  get advertiserName(): string {
    const { advertiserName } = this.value;
    return advertiserName;
  }

  set advertiserName(value: string) {
    this.value = { advertiserName: value };
  }

  get allowEmail(): boolean {
    const { allowEmail } = this.value;
    return allowEmail;
  }

  set allowEmail(value: boolean) {
    this.value = { allowEmail: value };
  }

  get allowPhone(): boolean {
    const { allowPhone } = this.value;
    return allowPhone;
  }

  set allowPhone(value: boolean) {
    this.value = { allowPhone: value };
  }

  get captureAdditional(): CaptureAdditionalItem[] | null {
    const { captureAdditional } = this.value;
    return captureAdditional;
  }

  set captureAdditional(value: CaptureAdditionalItem[] | null) {
    this.value = { captureAdditional: value };
  }

  get captureName(): boolean {
    const { captureName } = this.value;
    return captureName;
  }

  set captureName(value: boolean) {
    this.value = { captureName: value };
  }

  get countryCode(): string | null {
    const { countryCode } = this.value;
    return countryCode;
  }

  set countryCode(value: string | null) {
    this.value = { countryCode: value };
  }

  get disableCountryPicker(): boolean {
    const { disableCountryPicker } = this.value;
    return disableCountryPicker;
  }

  set disableCountryPicker(value: boolean) {
    this.value = { disableCountryPicker: value };
  }

  get errorAdditional(): string | null {
    const { errorAdditional } = this.value;
    return errorAdditional;
  }

  set errorAdditional(value: string | null) {
    this.value = { errorAdditional: value };
  }

  get errorName(): string | null {
    const { errorName } = this.value;
    return errorName;
  }

  set errorName(value: string | null) {
    this.value = { errorName: value };
  }

  get errorPhone(): string | null {
    const { errorPhone } = this.value;
    return errorPhone;
  }

  set errorPhone(value: string | null) {
    this.value = { errorPhone: value };
  }

  get errorEmail(): string | null {
    const { errorEmail } = this.value;
    return errorEmail;
  }

  set errorEmail(value: string | null) {
    this.value = { errorEmail: value };
  }

  get errorPrimaryConsent(): string | null {
    const { errorPrimaryConsent } = this.value;
    return errorPrimaryConsent;
  }

  set errorPrimaryConsent(value: string | null) {
    this.value = { errorPrimaryConsent: value };
  }

  get errorToken(): string | null {
    const { errorToken } = this.value;
    return errorToken;
  }

  set errorToken(value: string | null) {
    this.value = { errorToken: value };
  }

  get loading(): boolean {
    const { loading } = this.value;
    return loading;
  }

  set loading(value: boolean) {
    this.value = { loading: value };
  }

  get marketingConsentTerms(): string {
    const { marketingConsentTerms } = this.value;
    return marketingConsentTerms;
  }

  set marketingConsentTerms(value: string) {
    this.value = { marketingConsentTerms: value };
  }

  get nameType(): NameType | null {
    const { nameType } = this.value;
    return nameType;
  }

  set nameType(value: NameType | null) {
    this.value = { nameType: value };
  }

  get showPrimaryConsent(): boolean {
    const { showPrimaryConsent } = this.value;
    return showPrimaryConsent;
  }

  set showPrimaryConsent(value: boolean) {
    this.value = { showPrimaryConsent: value };
  }

  get showCountryCode(): boolean {
    const { showCountryCode } = this.value;
    return showCountryCode;
  }

  set showCountryCode(value: boolean) {
    this.value = { showCountryCode: value };
  }

  get showMarketingConsent(): boolean {
    const { showMarketingConsent } = this.value;
    return showMarketingConsent;
  }

  set showMarketingConsent(value: boolean) {
    this.value = { showMarketingConsent: value };
  }

  get inputValue(): string {
    const { inputValue } = this.value;
    return inputValue;
  }

  set inputValue(value: string) {
    this.value = { inputValue: value };
  }
}
