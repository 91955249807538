import React, { Component } from 'react';
import Presenter from './presenter';
import Model, { StateModel } from './model';
import View from './view';

export interface IndexProps {
  minAge: number;
  format: string;
  onComplete: (date: any) => void;
  onError: () => void;
}

class AgeLanding extends Component<IndexProps> {
  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { minAge, onComplete, onError } = props;
    this.presenter = new Presenter(new Model(this), minAge, onComplete, onError);
  }

  render(): JSX.Element {
    const { format } = this.props;

    return <View {...(this.state as StateModel)} presenter={this.presenter} format={format} />;
  }
}

export default AgeLanding;
