import clsx from 'clsx';
import i18next, { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CheckBox from '../../../components/checkbox';
import Popup from '../../../components/popup';
import { Translator } from '../../../types/common';
import styles from './index.module.scss';
import { LanguagePolicies } from '../../../types/config';

interface IndexProps {
  t: TFunction;
  open: boolean;
  isContinueEnabled: boolean;
  onContinue: VoidFunction;
  onClose: VoidFunction;
  advertiserName: string;
  showPrimaryConsent: boolean;
  showMarketingConsent: boolean;
  marketingConsentTerms: string;
  languagePolicies: LanguagePolicies;
  primaryChecked: boolean;
  marketingChecked: boolean;
  onPrimaryChange: (checked: boolean) => void;
  onMarketingChange: (checked: boolean) => void;
}

function ConsentPopup(props: IndexProps): JSX.Element {
  const {
    t,
    open,
    isContinueEnabled,
    onContinue,
    onClose,
    advertiserName,
    showPrimaryConsent,
    showMarketingConsent,
    marketingConsentTerms,
    languagePolicies,
    primaryChecked,
    marketingChecked,
    onPrimaryChange,
    onMarketingChange,
  } = props;

  const showClientConsent =
    i18next.exists('claim.landing.consent_client') && t('claim.landing.consent_client');

  const consentTranslator: Translator = (tF: TFunction): string => {
    let consentDefaultText = '';

    if (languagePolicies) {
      // Default could be: Platform Only, Platform + Client, or Custom Only
      // For Custom, locale key to overridden
      if (showPrimaryConsent) {
        // Add platform or custom
        consentDefaultText = tF('claim.landing.consent_primary_tokenized', {
          terms: languagePolicies.platform_terms,
          privacy: languagePolicies.platform_privacy,
          nft: languagePolicies.platform_nft,
        });
        // Add client text if needed (only for platform)
        if (showClientConsent) {
          consentDefaultText += ` ${tF('claim.landing.consent_client')}`;
        }
      }
    }

    return consentDefaultText;
  };

  return (
    <Popup
      title="claim.landing.consent_popup_title"
      continueButtonTitle="claim.landing.consent_popup_button_continue"
      closeButtonTitle="claim.landing.consent_popup_button_close"
      isContinueEnabled={isContinueEnabled}
      open={open}
      onContinue={(): void => {
        onContinue();
      }}
      onClose={(): void => {
        onClose();
      }}
      delay={undefined}
    >
      <div className={styles.additionalContentsContainer}>
        {showPrimaryConsent && (
          <CheckBox
            className={clsx(
              styles.checkbox,
              'claim-landing-checkbox',
              'claim-landing-checkbox-primary'
            )}
            text={consentTranslator(t)}
            checked={primaryChecked}
            onCheckedToggle={(isChecked: boolean): void => {
              onPrimaryChange(isChecked);
            }}
          />
        )}
        {showMarketingConsent && (
          <CheckBox
            className={clsx(
              styles.checkbox,
              'claim-landing-checkbox',
              'claim-landing-checkbox-marketing'
            )}
            text={t('claim.landing.consent_marketing', {
              advertiser: advertiserName,
              terms: marketingConsentTerms,
              interpolation: { escapeValue: false },
            })}
            checked={marketingChecked}
            onCheckedToggle={(isChecked: boolean): void => {
              onMarketingChange(isChecked);
            }}
          />
        )}
      </div>
    </Popup>
  );
}

export default withTranslation()(ConsentPopup);
