import Log from './log';

/* eslint-disable no-useless-escape */
export function validateEmail(input: string): boolean {
  const validation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (validation.test(String(input).toLowerCase())) {
    return input.indexOf("'") === -1;
  }
  return false;
}

export function validatePhone(input: string): boolean {
  return /^[ \+\(\)\-0-9]+$/.test(input);
}

export function isHttps(url: string): boolean {
  try {
    // Parse and validate the url using the URL object
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol === 'https:') {
      return true;
    }
    Log.error(`URL failed protocol validation: ${url}`);
    return false;
  } catch (error) {
    // The URL is invalid or cannot be parsed
    return false;
  }
}

/**
 * Returns a sanitized cdn url ensuring a https protocol and known hostname.
 * Fragments and query parameters are removed.
 */
export function sanitizeCdnUrl(url: string | URL): URL | undefined {
  // Whitelisted CDN domains
  const validDomains = [
    'https://cdn-alpha.smartmedialabs.io',
    'https://cdn-beta.smartmedialabs.io',
    'https://cdn.smartmedialabs.io',
  ];

  // Construct URL objects
  const inputURL = typeof url === 'string' ? new URL(url) : url;
  const validDomainURLs = validDomains.map((base) => new URL(base));

  // Check if the input url has a valid protocol and domain
  const trustedURL = validDomainURLs.find(
    (cdnURL) => cdnURL.protocol === 'https:' && cdnURL.hostname === inputURL.hostname
  );

  if (!trustedURL) {
    return undefined;
  }

  // Build a new URL, use only the hostname and path components of the input url
  return new URL(inputURL.pathname, `https://${inputURL.hostname}`);
}
